
import { Chip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../../actions/PopUpActions';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';

const Rows = [
  {
    title: 'Уровень',
    key: 'LVL'
  },
  {
    title: 'Сумма потраченых средств',
    key: 'CASH_COUNT'
  },
  {
    title: 'Кешбек',
    key: 'CASHBACK_SUZE'
  },
]


class Index extends React.Component {
  constructor(props) {
    super()
    this.state = {
      filter: null,
    }
    this.input = []
  }



  componentDidMount() {

  }

  getServTitle(data) {
    try {
      const { filter, Data } = this.state
      const { YC } = this.props
      if (YC.services.length == 0)
        return null

      let serv = YC.services.find(serv => serv.id == data.YC_ID)
      if (serv == null)
        return null

      else
        return [
          <h4 className='m-0'>{serv.title}</h4>,
          <br />,
          this.props.COMPANYS.length == 0 ? null :
            serv.companys.length == 5 ?
              <small style={{
                background: 'rgba(255,255,255,0.1)'
              }} className='me-2 mt-2 p-1 rounded'>Все филиалы</small>
              :
              serv.companys.map(com_id =>
                <small style={{
                  background: 'rgba(255,255,255,0.1)'
                }} className='me-2 mt-2 p-1 rounded'>{this.props.COMPANYS.find(c => c.ID == com_id).TITLE}</small>
              )
        ]
    } catch (e) { return null }
  }


  bonysType(type, value) {
    switch (type) {
      case 'FIX': return `${value}Б`
      case 'IND': return `${value}%`


      default: return `${this.props.VARS[type] != null ? this.props.VARS[type].value : value}%`
    }
  }

  render() {
    const { SELECTED_SERVICES, YC, SELF_CUSTOMER } = this.props
    const { filter } = this.state
    const { state, props } = this

    let currentNext = props.BONUS_LVL[SELF_CUSTOMER.LVL]

    let nextLvl = props.BONUS_LVL[SELF_CUSTOMER.LVL + 1]

    return (
      <MainView>
        <h5>Правила начисления и списания бонусных баллов:</h5>
        <p>Начисление:</p>
        <ul className='w-75'>
          <li>За посещение любой из студий Вам будет автоматически начислен кешбэк в соответствии с Вашим уровнем в системе лояльности (ваш уровень: {SELF_CUSTOMER.LVL} , кешбек: {SELF_CUSTOMER.CASHBACK_SUZE * 100}%)</li>
          <li>Кешбэк начисляется на все услуги, в том числе на акционные</li>
        </ul>

        <p>Списание:</p>
        <ul className='w-75'>
          <li>Вы можете оплачивать <b>{currentNext.DISCOUNT_SIZE * 100}%</b> от суммы чека баллами</li>
          <li>Баллами можно оплачивать все услуги, в том числе – акционные</li>
          <li>Для списания баллов, пожалуйста, обратитесь к администратору до оплаты визита</li>

        </ul>

        <p>Посмотреть количество накопленных баллов можно в разделе “Главная”</p>

        <hr />

        <h3 className='mb-0'>Уровни бонусной системы</h3>
        {nextLvl == null ? null :
          <p style={{
            color: 'rgba(240, 156, 96, 1)'
          }}>До переходна на {nextLvl.LVL} уровень осталось {GF.makeCoast(nextLvl.CASH_COUNT - props.SELF_CUSTOMER.ALL_COAST)} руб</p>}

        <small className='w-50 mt-0 mb-3 d-block'>При оплате услуг в сети салонов Rich Nails у вас будет повышаться уровень в системе и вы будите получать больший процент кешбка</small>


        {props.BONUS_LVL.map((bl, i) => <>
          <div style={{
            display: 'flex',
            gap: 20,
            height: 32,
            zIndex: 1,
            position: 'relative',
            alignItems: 'center',
          }}>
            <Chip color={bl.LVL == props.SELF_CUSTOMER.LVL ? 'warning' : 'default'} label={bl.LVL} />
            <div>
              <p className='m-0 p-0'>Кешбек: {GF.makeCoast(bl.CASHBACK_SUZE * 100)}% | Списание до {GF.makeCoast(bl.DISCOUNT_SIZE * 100)}%</p>
              <small className='m-0 p-0 d-block'>Переход: {GF.makeCoast(bl.CASH_COUNT)} руб</small>
            </div>
          </div>
          {props.BONUS_LVL[i + 1] == null ? null :
            <div style={{
              marginLeft: 13,
              zIndex: 5,
              position: 'relative',
              marginTop: -1,
              marginBottom: -1,
              width: 5,
              height: 30,
              background: 'rgba(41, 41, 41, 1)'
            }}>
              <div style={{
                width: 5,
                height: bl.LVL > props.SELF_CUSTOMER.LVL ? '0%' :
                  bl.LVL < props.SELF_CUSTOMER.LVL ? '100%'
                    : 600 / props.BONUS_LVL[i + 1].CASH_COUNT * 100 + '%',
                background: '#ffa726'
              }} />
            </div>
          }
        </>
        )}


      </MainView>
    )
  }
}

const MainView = styled.div`
& li{
  color:white;
}
`





const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg))
  };
};


const mapStateToProps = (store) => {

  return {
    SELECTED_SERVICES: store.page.SELECTED_SERVICES,
    CLIENTS: store.page.CLIENTS,
    BONUS_LOG: store.page.BONUS_LOG,
    BONUS_LVL: store.page.BONUS_LVL,
    VARS: store.page.VARS,
    YC: store.page.YC,
    user: store.user.data,
    SHOP_ITEMS: store.page.SHOP_ITEMS,
    SELF_CUSTOMER: store.page.SELF_CUSTOMER,
    COMPANYS: store.page.COMPANYS,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

