import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getDB_Data,getVARS, getUsers } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF, GlobalFunctions } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import Input_image from '../../../components/Input_image';
import { Button, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ComboBox from '../../../components/moduls/ComboBox';


const fields = [
  {
    title:'Логин',
    name:'LOGIN',
  },
  
  {
    title:'Пароль',
    name:'PASSWORD',
  },
  
]

class POP_User extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          Self:{}
        }
        this.input={}
        this.previosValue = null
        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }
    componentDidMount(){
      
      if(this.props.ID_OBJECT != null)
      this.getSelf()
    }

    getSelf(){
      const {state,props} = this

      let Self = props.USERS.find(type=>type.ID==this.props.ID_OBJECT)
      
      this.setState({Self:{...{...Self}}})
     
    setTimeout(()=>{
      Object.keys(Self).forEach(key=>{
        try{
          this.input[key].value=Self[key]
        }catch(e){}
      })
    },1)
      

     
    }

   
    Delete(){
      GF.API_task({
        operation:'deleteSome',
        Table:'USERS',
        ID:this.props.ID_OBJECT
      }).then(data => {
        this.props.getUsers()
        this.props.closePopUp()
      })
    }

    
    Save(){
      if(!GlobalFunctions.checkReqvireds(this.input)) return
      

      if(this.state.Self.ROLE==null){
        this.props.openPopUp(<Alert text='Веберите роль для пользователя'/>)
        return
      }

      let userOther = this.props.USERS.findIndex(u=>u.LOGIN==this.input.LOGIN.value)
      
      if(userOther!=-1){
        this.props.openPopUp(<Alert text='Логин уже занят'/>)
        return
      }

      let data = {}
      Object.keys(this.input).forEach(key=>{
          try{
              let val = this.input[key].value
              data[key] = val.length == 0 ? null : val
          }catch(e){}
      })

      let reqv = {
          operation:'createUpdateSome',
          Table:'USERS',
          data
      }
      reqv.data.ROLE='MNGR'

      if(this.props.ID_OBJECT != null)
          reqv.ID = this.props.ID_OBJECT
      else{
        reqv.data.ID_STATUS=2
        reqv.data.ROLE = this.state.Self.ROLE
      }

      GlobalFunctions.API_task(reqv).then(res=>{
          this.props.getUsers()
          this.props.closePopUp()
      }).catch(e=>{debugger})
  }
  setUserStatus(newStatus){
    GlobalFunctions.API_task({
      operation:'setUserStatus',
      ID_STATUS:newStatus,
      ID_USER:this.state.Self.ID
    }).then(res=>{
      this.props.getUsers()
      this.props.closePopUp()
    })
  }

   
  
    render (){
        const {state,props} = this
        const {Self} = state
        if(Self.ID==null && props.ID_OBJECT!=null) return <div/>

        return (
          <POP> 
            <div style={{
              display:'flex',
              marginBottom:30,
              justifyContent:'space-between',
              alignItems:'center'
            }}>
              {props.ID_OBJECT!=null?<>
              <h4 className='m-0'>Редактировать пользователя</h4>
              <Chip color={
                      Self.ID_STATUS == 2?'success':
                      Self.ID_STATUS == 1?'warning':
                      Self.ID_STATUS == 3?'info':'primary'
                    } label={Self.STATUS_TITLE}/>
              </>:
                <h4 className='m-0'>Создать пользователя</h4>
              }
            </div>



            <FormControl fullWidth
              className='mb-2'
              size='small'
              variant='filled'>
                <InputLabel>Роль</InputLabel>
                <Select
                disabled={this.props.ID_OBJECT!=null}
                  value={Self.ROLE}
                  label="Роль"
                  onChange={(e)=>{
                    this.setState({Self:{
                      ...Self,
                      ROLE:e.target.value
                    }})
                  }}>
                    <MenuItem value='MNGR'>Менеджер филиала</MenuItem>
                    <MenuItem value='FRCH'>Франчайзи</MenuItem>
                    <MenuItem value='DEVP'>Разработчик сайта</MenuItem>
                </Select>
            </FormControl>
          
            {fields.map(f=>
               <Input Ref={this.input} {...f} />
            )}

            <hr/>

            <div style={{
               display:'flex',
               justifyContent:'space-between',
               alignItems:'center'
            }}>
              {props.ID_OBJECT==null?null:<>
              <Button size='small' color='error' onClick={()=>this.Delete()}>Удалить</Button>
              
              <Button size='small' color='info' onClick={()=>this.setUserStatus(Self.ID_STATUS==2?3:2)}>{Self.ID_STATUS==2?'Заблокировать':'Разблокировать'}</Button>
              </>}

              <Button size='small' color='primary' onClick={()=>this.Save()}>Сохранить</Button>
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:440px;
  overflow-y:auto;
  max-height:80vh;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg,fr)=>dispatch(getDB_Data_lazy(arg,fr)),
        getDB_Data:(arg,SDF)=>dispatch(getDB_Data(arg,SDF)),
        getUsers:(arg)=>dispatch(getUsers(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      USERS:store.page.USERS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_User)