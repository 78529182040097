import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp, closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data_lazy, getVARS } from '../../../actions/PageActions';
import Input, { Label } from '../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import Alert from '../../PopUp/Views/Alert';
import Question from '../../PopUp/Views/Question';
import { Button, LinearProgress, TextField } from '@mui/material';
import { Pallet } from '../../../constants/Constants';
import { DatePicker } from '@mui/lab';
import moment from 'moment';



class POP_UseBonusCoins extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      showAll: false,
      manualSummBall: 0,
      canPush: true,
      selectedDate: moment(),
      pop_filter: {
        category: null
      },
    }
    this.input = {
      dataWindow: {}
    }

    this.dateChanged = false

  }

  componentDidMount() {
    try {
      this.input.dataWindow.Balls.focus()
      //this.input.dataWindow.TITLE.focus()
    } catch (e) {

    }
  }
  Go() {

    if (!this.state.canPush) return

    const { self, manualSummBall } = this.state
    let selected = this.state.selected
    const { currentCount, manual } = this.props
    let sumBal = manual ? manualSummBall : this.SummBall()

    if (currentCount == 0) return
    if (sumBal <= 0) {
      return
    }

    if (currentCount < sumBal) {
      new Promise((resolve, reject) => {
        this.props.openPopUp(<Question text={`Недостаточно баллов для списания. Списать, сколько хватает?`}
          buttons={[
            <Button size='small' onClick={() => resolve()}>Да, списать {currentCount}Б</Button>,
            <Button size='small' color='error' onClick={() => reject()}>Отмена</Button>
          ]} />)
      }).then(() => {
        sumBal = currentCount
        selected = [{ ID: -1, COAST: sumBal }]

        this.setState({ canPush: false })


        this.props.callBack(currentCount)
      }).finally((e) => {
        this.props.closePopUp()
      })
    } else {
      if (manual) {
        selected = [{ ID: -1, COAST: sumBal, }]
      }
      this.setState({ canPush: false })



      this.props.callBack(sumBal, this.dateChanged ? this.state.selectedDate : null)
    }

  }



  filteredServices() {
    let result = []
    const { pop_filter, selected, showAll } = this.state
    const { SHOP_ITEMS, notAllowedInFilter } = this.props

    let data = []

    if (pop_filter.category == 0) {//если оплата 7%
      data = [...[...this.props.YC.services]]
      data.forEach((d, i) => {
        data[i] = {
          ...d,
          ID: d.id,
          TITLE: d.title,
          COAST: d.price_max,
          COMPANYS: d.companys.map(com => {
            return this.props.COMPANYS.find(comp => comp.ID == com)
          })
        }
      })

      if (pop_filter != null) {

        if (pop_filter.title != null) {
          data = data.filter(item => item ? item.TITLE.toUpperCase().indexOf(pop_filter.title) != -1 : '')
        }

        if (pop_filter.company != null) {
          data = data.filter(item => item ? item.COMPANYS.findIndex(com => com ? com.ID == pop_filter.company : '') != -1 : '')
        }

      }
    } else {
      data = SHOP_ITEMS.slice(0)

      data.forEach((d, i) => {
        if (d.ID_SHOP_CATEGORY == 2) {//если это услуга
          data[i] = {
            ...d,
            ...d.YC
          }
          delete (data[i].YC)
        }
      })
      data = data.filter(item => item ? item.ID > 0 : '')

      if (pop_filter != null) {

        if (pop_filter.title != null) {
          data = data.filter(item => item.TITLE ? item.TITLE.toUpperCase().indexOf(pop_filter.title) != -1 : '')
        }

        if (pop_filter.company != null) {
          data = data.filter(item => item ? item.COMPANYS.findIndex(com => com ? com.ID == pop_filter.company : '') != -1 : '')
        }

        if (pop_filter.category != null) {
          data = data.filter(item => item ? item.ID_SHOP_CATEGORY == pop_filter.category : '')
        }
      }


    }


    let isMoreThen40 = false

    if (data.length >= 10 && !showAll) {
      data = data.splice(0, 10)
      isMoreThen40 = true
    }




    let onClick = (serv) => {

      let selected_tmp = this.state.selected.splice(0)
      let index = selected_tmp.findIndex(s => s.ID == serv.ID)
      if (index == -1)//если не выбрана услуга
        selected_tmp.push(serv)
      else
        selected_tmp.splice(index, 1)

      this.setState({ selected: selected_tmp })
    }

    
    
    return [...data.map(item =>
      <Services key={'item_' + item.ID} onClick={() => onClick(item)} selected={selected.findIndex(s => s.ID == item.ID) != -1}>

        <p style={{ fontSize: 13, fontWeight: '500' }} className='mb-0 mt-4 '>{item.TITLE}</p>
        
        <Label style={{ color: '#F09C60' }}>{GF.makeCoast(item.COAST)} руб  {item.SHOP_CATEGORY_TITLE}</Label>
        {item.COMPANYS.length == this.props.COMPANYS.length ?
          <small style={{ background: Pallet.Light.White }} className='me-2 mt-0 p-1 rounded'>Все филиалы</small> :
          item.COMPANYS.length > 0 ?
              item.COMPANYS.map(data =>
                data ?
                <small style={{ background: Pallet.Light.White }} className='me-2 mt-0 p-1 rounded'>{data.TITLE.replace('Rich Nails ', '')}</small> :
                ''
              ) :
              <small style={{ background: Pallet.Light.White }} className='me-2 mt-0 p-1 rounded'>Нет</small>
        }
      </Services>
    )
      , isMoreThen40 ? <p className='mt-5 mb-5' onClick={() => {
        this.setState({ showAll: true })
      }}>Показать все</p> : null
    ]

    // return [...data.map(item =>
    //   <Services key={'item_' + item.ID} onClick={() => onClick(item)} selected={selected.findIndex(s => s.ID == item.ID) != -1}>

    //     <p style={{ fontSize: 13, fontWeight: '500' }} className='mb-0 mt-4 '>{item.TITLE}</p>

    //     <Label style={{ color: '#F09C60' }}>{GF.makeCoast(item.COAST)} руб  {item.SHOP_CATEGORY_TITLE}</Label>
        
    //     <small style={{ background: Pallet.Light.White }} className='me-2 mt-0 p-1 rounded'>Все филиалы - {item.COMPANYS.length}</small> :
        
    //   </Services>
    // )
    //   , isMoreThen40 ? <p className='mt-5 mb-5' onClick={() => {
    //     this.setState({ showAll: true })
    //   }}>Показать все</p> : null
    // ]

  }

  SummBall() {
    const { selected } = this.state

    let sum = 0
    selected.forEach(s => {
      if (this.state.pop_filter.category == 0)//если это услуга
        sum += Math.floor(s.COAST * this.props.currentUser.DISCOUNT_SIZE)
      else
        sum += s.COAST
    })
    return sum
  }

  render() {
    let sumBal = this.SummBall()
    const { state, props } = this
    const { self, pop_filter, manualSummBall } = this.state
    const { SHOP_CATEGORIS, COMPANYS, currentCount, manual } = this.props


    if (manual) {
      return (
        <POP small>
          <p>Сколько баллов списать со счета клиента</p>
          <TextField
            color='warning'
            variant='filled'
            autoFocus
            label='Количество баллов'
            placeholder='от 1 балла'
            type='number'
            className='mb-3 w-100'
            value={state.manualSummBall}
            onChange={(e) => {
              this.setState({ manualSummBall: e.target.value })
            }}
          />

          <DatePicker
            value={state.selectedDate}
            onChange={(newValue) => {
              this.dateChanged = true
              this.setState({ selectedDate: moment(newValue) })
            }}
            label="Дата списания"
            renderInput={(params) => <TextField
              className='mb-5 w-100'
              variant='filled'
              color='warning'{...params} />}
          />

          <div className='mb-3' />
          {manualSummBall > 0 ?
            <Itog isError={currentCount < manualSummBall}>Итого: {GF.makeCoast(manualSummBall)} б

              <Button size='small' disabled={!this.state.canPush} className="float-end" onClick={() => {
                this.Go()
              }}>Списать баллы
                {this.state.canPush ? null :
                  <LinearProgress color='warning' style={{
                    position: 'absolute',
                    width: '100%',
                    bottom: 0
                  }} />}
              </Button>


            </Itog> : null}
        </POP>
      )
    }

    return (
      <POP>

        <div className='row'>
          <div className='col-4'>
            <Input
              onKeyUp={(e) => {
                this.setState({
                  pop_filter: {
                    ...pop_filter,
                    title: e.target.value == '' ? null : e.target.value.toUpperCase()
                  }
                })
              }}
              title='Название'
              placeholder=''
              name='TITLE'
              Ref={this.input.dataWindow} />
          </div>
          <div className='col-4'>
            <Label for='company' >Филиал</Label>
            <select id='company' className='form-control' onChange={(e) => {
              this.setState({
                pop_filter: {
                  ...pop_filter,
                  company: e.target.value == -1 ? null : e.target.value
                }
              })
            }}>
              <option value={-1} >Любой</option>
              {COMPANYS.map(data =>
                <option value={data.ID}>{data.TITLE.replace('Rich Nails ', '')}</option>
              )}
            </select>
          </div>
          <div className='col-4'>
            <Label for='company' >Категория</Label>
            <select id='company' className='form-control' onChange={(e) => {
              this.setState({
                pop_filter: {
                  ...pop_filter,
                  category: e.target.value == -1 ? null : e.target.value
                }, selected: []
              })
            }}>
              <option value={-1} >Любая</option>
              <option value={0} >{GF.makeCoast(this.props.currentUser.DISCOUNT_SIZE * 100)}% на услуги</option>
              {SHOP_CATEGORIS.map(data =>
                <option value={data.ID}>{data.TITLE}</option>
              )}
            </select>
          </div>
        </div>

        <hr className='mb-0' />
        {this.state.pop_filter.category == 1 ?
          <small>Оплата полной стоимости товаров баллами</small>
          : this.state.pop_filter.category == 2 ?
            <small>Оплата полной стоимости услуг баллами</small>
            : this.state.pop_filter.category == -1 || this.state.pop_filter.category == null ?
              <small>Оплата полной стоимости товаров и услуг баллами</small>
              : <small>Оплата до {GF.makeCoast(this.props.currentUser.DISCOUNT_SIZE * 100)}-ми % от стоимости услуг баллами на {this.props.currentUser.LVL} уровне клиента</small>
        }
        <ScrollView>
          {this.filteredServices()}
        </ScrollView>

        {sumBal > 0 ?
          <Itog isError={currentCount < sumBal}>Итого: {GF.makeCoast(sumBal)} б

            <Button size='small' disabled={!this.state.canPush} className="float-end" onClick={() => {
              this.Go()
            }}>Списать баллы
              {this.state.canPush ? null :
                <LinearProgress color='warning' style={{
                  position: 'absolute',
                  width: '100%',
                  bottom: 0
                }} />}
            </Button>


          </Itog> : null}
      </POP>
    )
  }
}

const POP = styled.div`
  width:${props => props.small ? 300 : 500}px;
  `


const Itog = styled.p`
  line-height: 30px;
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-size: 14px;
  font-weight: bold;

  border-radius: 5px;
  background: rgba(0,0,0,0.8);
  backdrop-filter:blur(30px);
  padding: 10px 20px;
  box-shadow: 0px 5px 30px rgba(0,0,0,0.1);
  width: calc(100% - 20px);
  ${props => props.isError ? 'color:red' : null}
  `

const Services = styled.div`
  padding-left:5px;
  border-left: 5px solid white;
  transition:.3s;
  cursor:pointer;
  margin-left:-5px;
  ${props => props.selected ? `
  margin-left:0px;
  border-left: 5px solid #c2965c;
  `: null}
  `
const ScrollView = styled.div`
  overflow-y:auto;
  padding-bottom:200px;
  max-height:60vh;
  `




const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getDB_Data_lazy: (arg) => dispatch(getDB_Data_lazy(arg)),
  }
}


const mapStateToProps = (store) => {

  return {
    YC: store.page.YC,
    SHOP_ITEMS: store.page.SHOP_ITEMS,
    COMPANYS: store.page.COMPANYS,
    SHOP_CATEGORIS: store.page.SHOP_CATEGORIS,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_UseBonusCoins)