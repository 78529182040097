
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../../actions/PopUpActions';
import { Pallet } from '../../../../constants/Constants';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import { Label } from '../../../components/moduls/Input';
import { Card } from '../ClientCompanys';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           filter:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }


    render (){
        const {YC,SHOP_ITEMS,type,COMPANYS} = this.props
        const {filter} = this.state

        let data = []
        if(type=='tovar'){
            
            data = filter==null?SHOP_ITEMS.filter(sh=>sh.ID_SHOP_CATEGORY==1):SHOP_ITEMS.filter(sh=>sh.ID_SHOP_CATEGORY==1 && sh.TITLE.toUpperCase().indexOf(filter)!=-1).slice(0)
            
        }else{
            data = filter==null?SHOP_ITEMS.filter(sh=>sh.ID_SHOP_CATEGORY==2):SHOP_ITEMS.filter(sh=>sh.ID_SHOP_CATEGORY==2 && sh.TITLE.toUpperCase().indexOf(filter)!=-1).slice(0)
        }

        return (
            <MainView>
                 <div className='row'>
                     <div className='col-lg-3 col-md-4'>
                         <Search placeholder='Поиск' className='form-control float-start d-block ' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>
                     </div>
                 </div>
                 
                 <div className='row pt-3'>
                     <div className='col-lg-6 col-md-10 order-lg-1 order-md-2 order-sm-2 order-xs-2'>
                         <div className='row row-cols-1'>
                             {data.map(d=>
                             d.ID<0?null:
                                 <div className='col mb-3'>
                                     <Card>
                                         <h5 className='pt-3'>{d.TITLE}</h5>
                                         <p>{GF.makeCoast(d.COAST)} Б</p>
                                     </Card>
                                 </div>
                             )}
                         </div>
                     </div>
                        {type=='tovar'?
                            <div className='col-lg-6 col-md-12 order-lg-2 order-md-1 order-sm-1 order-xs-1'>
                                 <h5>Товары за баллы</h5>
                                 <p>В списке представлены товары, которые можно приобрести, оплатив 100% от стоимости товаров<br/>Для покупки товаров за баллы, пожалуйста, обратитесь к администратору студии</p>
                            </div>
                            :
                            <div className='col-lg-6 col-md-12 order-lg-2 order-md-1 order-sm-1 order-xs-1'>
                                 <h5>Услуги за баллы</h5>
                                 <p>В списке представлены услуги, которые можно приобрести, оплатив 100% от стоимости<br/>Для покупки услуг за баллы, пожалуйста, обратитесь к администратору студии</p>
                            </div>
                        }
                            
                 </div>
                
            </MainView>
         )
        
      
    }
}

const MainView = styled.div`

`

export const Search = styled.input`
width:100%;
background:${Pallet.Gray};
border:${Pallet.Gray} 1px solid;
color:white !important;
&:active, &:focus{
  background:${Pallet.Gray};
  border:${Pallet.Primary.Accent} 1px solid;
}
`





const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SELECTED_SERVICES:store.page.SELECTED_SERVICES,
        CLIENTS:store.page.CLIENTS,
        BONUS_LOG:store.page.BONUS_LOG,
        YC:store.page.YC,
        user: store.user.data,
        SHOP_ITEMS:store.page.SHOP_ITEMS,
        SELF_CUSTOMER:store.page.SELF_CUSTOMER,
        COMPANYS:store.page.COMPANYS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Index);

