import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getDB_Data,getVARS } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF, GlobalFunctions } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import Input_image from '../../../components/Input_image';

function substr(e,length){
    if(e.target.value.length>length){
      e.target.value=e.target.value.substr(0,length)
    }
}

const fields = [
  {
    title:'Название',
    name:'TITLE',
  },
  
  {
    title:'Адрес',
    name:'ADDRESS',
    maxlength:100,
    multyline:true,
    type:'textarea',
    onChange:e=>substr(e,100)
  },
  {
    title:'Как пройти',
    name:'HOW_TO_WALK',
    maxlength:300,
    type:'textarea',
    multyline:true,
    onChange:e=>substr(e,300)
  },
  {
    title:'Телефон',
    name:'PHONE',
  },
]

class POP_Company extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          Self:{}
        }
        this.input={}
        this.previosValue = null
        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }
    componentDidMount(){
      
      if(this.props.ID_OBJECT != null)
      this.getSelf()
    }

    getSelf(){
      const {state,props} = this

      let Self = props.COMPANYS.find(type=>type.ID==this.props.ID_OBJECT)
      
      this.setState({Self:JSON.parse(JSON.stringify(Self))})
     
    setTimeout(()=>{
      Object.keys(Self).forEach(key=>{
        try{
          this.input[key].value=Self[key]
        }catch(e){}
      })
    },1)
      

     
    }

   
    Delete(){
      GF.API_task({
        operation:'deleteBonusKATEG',
        ID_OBJECT:this.props.ID_OBJECT
      }).then(data => {
        this.props.getDB_Data_lazy('BONUS_LOG_TYPES')
        this.props.closePopUp()
      })
    }

    
    Save(){
      if(!GlobalFunctions.checkReqvireds([this.input.TITLE])) return
      
      let data = {}
      Object.keys(this.input).forEach(key=>{
          try{
              let val = this.input[key].value
              data[key] = val.length == 0 ? null : val
          }catch(e){}
      })

    
      let reqv = {
          operation:'createUpdateSome',
          Table:'COMPANYS',
          data
      }
      if(this.props.ID_OBJECT != null)
          reqv.ID = this.props.ID_OBJECT

      

      GlobalFunctions.API_task(reqv).then(res=>{
          let promises = []
          this.props.getDB_Data_lazy('COMPANYS','IMAGES_URL')

          
          if(this.filesToUpload.length>0)
              promises.push(GlobalFunctions.API_files({
                  operation:'setIMAGE',
                  Table:'COMPANYS',
                  ID:res.ID
              },this.filesToUpload))

          if(this.filesToDelete.length>0)
              promises.push(GlobalFunctions.API_task({
                  operation:'deleteIMAGE',
                  ID:this.props.ID_OBJECT,
                  Table:'COMPANYS',
                  filesToDelete:JSON.stringify(this.filesToDelete)
              }))
          Promise.all(promises).then(()=>{
            this.props.getDB_Data_lazy('COMPANYS','IMAGES_URL')
          })
          this.props.closePopUp()
      }).catch(e=>{debugger})
  }

   
  
    render (){
        const {state,props} = this
        if(state.Self.ID==null && props.ID_OBJECT!=null) return <div/>

        return (
          <POP> 
            <h4>Редактировать филиал</h4>
          
            {fields.map(f=>
               <Input Ref={this.input} {...f} />
            )}

            <div className='mb-2' style={{height:140}}>
                <Input_image multiple={false} value={state.Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                    
                    this.filesToUpload=filesToUpload
                    this.filesToDelete=filesToDelete
                    
                }}/>
            </div>
            <div style={{
              flexDirection:'row',
              marginTop:20
            }}>
              
              <div className='btn btn-sm  btn-light float-end' onClick={()=>this.Save()}>Сохранить</div>
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:320px;
  overflow-y:auto;
  max-height:80vh;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg,fr)=>dispatch(getDB_Data_lazy(arg,fr)),
        getDB_Data:(arg,SDF)=>dispatch(getDB_Data(arg,SDF)),
        getVARS:(arg)=>dispatch(getVARS(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      COMPANYS:store.page.COMPANYS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_Company)