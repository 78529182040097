import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import {  getDB_Data_lazy,getVARS,getDB_Data } from '../../../actions/PageActions';
import Input, { Label } from '../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import Alert from '../../PopUp/Views/Alert';
import POP_UseBonusCoins from './POP_UseBonusCoins';
import { Autocomplete, Button, LinearProgress, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';


class POP_NewBonusLog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          self:{},
          BONUS_LOG:[],
          value:null,
          nowLoading:true,
          canPush:true
        }
       

        this.input=[]
      
    }

    addBals(){
      if(!GF.checkReqvireds(this.input)) return
      if(this.state.value==null) return

      if(!this.state.canPush) return
      this.setState({canPush:false})
      

      GF.API_task({
        operation:'addBalsCustom',
        ID_USER:this.props.user.ID,
        TYPE:this.state.value.TYPE,
        VALUE:this.input.VALUE.value,
        ID_COMPANY:this.props.selectedFilial
      }).then(data => {
        this.props.getDB_Data('getCLIENTS','CLIENTS',null,()=>{
          setTimeout(()=>{
            this.props.callBack()
            setTimeout(()=>this.props.closePopUp(),100)
          },10)
          
        })
        
       
      }).catch(()=>{
        this.setState({canPush:true})
      })
    }
  
    render (){
        const {self,BONUS_LOG,nowLoading} = this.state
        const {BONUS_LOG_TYPES,SHOP_ITEMS,YC,user} = this.props
        
        return (<POP> 
             <h4>Добавить запись</h4>
             <Autocomplete
              disablePortal
              size="small"
              
              className='mt-3 mb-2'
              value={this.state.value}
              onChange={(e,value)=>this.setState({value})}
              options={BONUS_LOG_TYPES.filter(b=>b.IS_SUSTEM==0)}
              getOptionLabel={(option) => option.DESCR}
              renderInput={(params) => <TextField style={{
                color:'white'
              }} color='warning' size='small' variant='filled' {...params} label="Тип записи" />}
            />  
            <Input
            
              title='Значение'
              name='VALUE'
              Ref={this.input}
            />
              
              <Button  disabled={!this.state.canPush} size='small' onClick={()=>{this.addBals()}} >
                Начислить
                {this.state.canPush?null:
                  <LinearProgress color='warning' style={{
                    position:'absolute',
                    width:'100%',
                    bottom: 0
                  }}/>}
              </Button>

          </POP>)
    }
  }

  const POP = styled.div`
  width:300px;

  & .pointer{
    cursor:pointer;
    opacity:0.8;
  }
  & .pointer:hover{
    opacity:1;
  }
  `

 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(tableName,JSONparseKey,callBack)=>dispatch(getDB_Data_lazy(tableName,JSONparseKey,callBack)),
        getDB_Data:(operation,tableName,JSONparseKey,callBack)=>dispatch(getDB_Data(operation,tableName,JSONparseKey,callBack)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      CLIENTS:store.page.CLIENTS,
      SHOP_ITEMS:store.page.SHOP_ITEMS,
      YC:store.page.YC,
      selectedFilial:store.page.selectedFilial,
      BONUS_LOG_TYPES:store.page.BONUS_LOG_TYPES,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_NewBonusLog)