export const Pallet ={
    Black:'#000',
    BlackLight:'#0B0B0B',
    Gray:'#191919',
    White:'#fff',
    Primary:{
        Accent:'#F09C60'
    },
    Light:{
        Accent:'rgba(240, 155, 95, 0.3)',
        Black:'rgba(0,0,0,0.3)',
        White:'rgba(255,255,255,0.05)'
    }
}

export const Styles = {
    inline:{
        display:'flex',
        flexDirection:'row',
        justifyContent:"space-between",
    }
}