import React from 'react';
import styled from 'styled-components';
import { Styles } from '../../../constants/Constants';
import { GlobalFunctions } from '../../../GlobalFunctions'

class Question extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
        this.key = GlobalFunctions.randomString()
    }

    componentDidMount() {

    }

    render() {
        const { text, buttons } = this.props
        return (
            <Content key={"Question" + this.key} className="Content content-fluid" style={this.props.style}>
                <p>{text}</p>
                <div style={Styles.inline}>
                    {buttons}
                </div>
            </Content>

        )
    }
}

export default Question;

const Content = styled.div`
width:430px;
`


