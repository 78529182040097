import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getDB_Data,getVARS } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF, GlobalFunctions } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import Input_image from '../../../components/Input_image';
import { Button, TextField } from '@mui/material';


const fields = [
  {
    label:'Уровень',
    disabled:true,
    key:'LVL',
  },
  
  {
    label:'Сумма потраченых средств для перехода (руб)',
    key:'CASH_COUNT',
  },

  {
    label:'Кешбек (%)',
    key:'CASHBACK_SUZE',
    onChange:(e)=>{
      let value = e.target.value+''
      if(value.length>0){
        value= value.replace(',','.').replace(/[^0-9.]/gm,'')
        
      }
      return value
    }
  },

  {
    label:'Скидка (%)',
    key:'DISCOUNT_SIZE',
    onChange:(e)=>{
      let value = e.target.value+''
      if(value.length>0){
        value= value.replace(',','.').replace(/[^0-9.]/gm,'')
        
      }
      return value
    }
  },

]

class POP_BonusDepth extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          Self:{},
          minCASH_COUNT:0,
          maxCASH_COUNT:null,
          errors:{}
        }
        this.input={}
    }

    componentDidMount(){
      if(this.props.ID_OBJECT != null){
        const perviosLVL = this.props.BONUS_LVL.find(lvl => lvl.LVL == this.props.ID_OBJECT-1)
        const nextLVL = this.props.BONUS_LVL.find(lvl => lvl.LVL == this.props.ID_OBJECT+1)
        
        this.setState({
          minCASH_COUNT:perviosLVL?.CASH_COUNT == null? null :perviosLVL.CASH_COUNT+1,
          maxCASH_COUNT:nextLVL?.CASH_COUNT == null? null :nextLVL.CASH_COUNT-1
        })
        this.getSelf() 
      }else{
        const lastLVL = this.props.BONUS_LVL.at(-1)
        const Self = {
          LVL:lastLVL.LVL+1,}
        this.setState({Self,minCASH_COUNT:lastLVL.CASH_COUNT+1})
      }
    }

    getSelf(){
      const {state,props} = this
      const Self = {...{...props.BONUS_LVL.find(obj=>obj.LVL == props.ID_OBJECT)}}
      Self.CASHBACK_SUZE = GF.makeRound((Self.CASHBACK_SUZE || 0) * 100)*1
      Self.DISCOUNT_SIZE = GF.makeRound((Self.DISCOUNT_SIZE || 0) * 100) *1

      this.setState({Self})
    }

   
  Delete(){
    const {state,props} = this
    GF.API_task({
      operation:'deleteBONUS_LVL',
      LVL:state.Self.LVL
    }).then(data => {
      this.props.getDB_Data('getBONUS_LVL','BONUS_LVL')
      this.props.closePopUp()
    })
  }

    
  Save(){
    const {state,props} = this
    const {Self} = state
    let haveErrors = false
    this.setState({errors:{}})
    
    
    if(Self.CASHBACK_SUZE==null || Self.CASHBACK_SUZE.length==0 || Self.CASHBACK_SUZE*1<0 || Self.CASHBACK_SUZE*1 == NaN || Self.CASHBACK_SUZE/100 == null || Self.CASHBACK_SUZE/100 ==NaN){
      haveErrors=true
      this.props.openPopUp(<Alert text='Укажите размер кешбека'/>)
      this.setState({errors:{...state.errors,CASHBACK_SUZE:true}})
    }

    if(Self.DISCOUNT_SIZE==null || Self.DISCOUNT_SIZE.length==0 || Self.DISCOUNT_SIZE*1<0 || Self.DISCOUNT_SIZE*1 == NaN || Self.DISCOUNT_SIZE/100 == null || Self.DISCOUNT_SIZE/100 ==NaN){
      haveErrors=true
      this.props.openPopUp(<Alert text='Укажите размер скидки'/>)
      this.setState({errors:{...state.errors,DISCOUNT_SIZE:true}})
    }

    if(Self.CASH_COUNT==null || Self.CASH_COUNT.length==0 || Self.CASH_COUNT*1<0){
      haveErrors=true
      this.props.openPopUp(<Alert text='Укажите сумму для перехода на уровень'/>)
      this.setState({errors:{...state.errors,CASH_COUNT:true}})
      
    }else{
      if(state.minCASH_COUNT!=null && Self.CASH_COUNT<state.minCASH_COUNT){
        haveErrors=true
        this.props.openPopUp(<Alert text={'Сумма для перехода на этот уровень не может быть меньше суммы предыдущего уровня ('+GF.makeCoast(state.minCASH_COUNT)+'руб)'}/>)
        this.setState({errors:{...state.errors,CASH_COUNT:true}})
      }

      if(state.maxCASH_COUNT!=null && Self.CASH_COUNT>state.maxCASH_COUNT){
        haveErrors=true
        this.props.openPopUp(<Alert text={'Сумма для перехода на этот уровень не может быть больше суммы следующего уровня ('+GF.makeCoast(state.maxCASH_COUNT)+'руб)'}/>)
        this.setState({errors:{...state.errors,CASH_COUNT:true}})
      }
    }


    if(haveErrors) return


    GF.API_task({
      isNewLVL:props.ID_OBJECT==null,
      operation:'updateBONUS_LVL',
      LVL:Self.LVL,
      CASH_COUNT:Self.CASH_COUNT,
      CASHBACK_SUZE:Self.CASHBACK_SUZE/100,
      DISCOUNT_SIZE:Self.DISCOUNT_SIZE/100,
    }).then(data => {
      this.props.getDB_Data('getBONUS_LVL','BONUS_LVL')
      this.props.closePopUp()
    })

    
  }

   
  
    render (){
        const {state,props} = this
        if(state.Self.LVL==null && props.ID_OBJECT!=null) return <div/>

        return (
          <POP> 
            <h4>Уровень скидки</h4>
          
            {fields.map(f=>
                <TextField style={{
                  color:'white'
                }}
                size="small"
                className='mb-2 w-100'
                color='warning'
                variant='filled'
                error={state.errors[f.key]===true}
                value={this.state.Self[f.key]}
                {...f}
                {...(f.key=='CASH_COUNT'?{
                  helperText: state.minCASH_COUNT!=null?'min:'+GF.makeCoast(state.minCASH_COUNT)+"р "+(state.maxCASH_COUNT==null?'':'    max:'+GF.makeCoast(state.maxCASH_COUNT)+"р "):null,
                  error: 
                    state.minCASH_COUNT!=null && this.state.Self[f.key] <= state.minCASH_COUNT 
                    || state.errors[f.key]===true ||
                    state.maxCASH_COUNT!=null && this.state.Self[f.key] >= state.maxCASH_COUNT ,
                  disabled: props.ID_OBJECT==0
                }:{})}


                
                onChange={(e)=>{
                  let tmp = {...{...this.state.Self}}
                  let value = f.onChange!=null ? f.onChange(e) : e.target.value*1

                  tmp[f.key] = value

                  this.setState({
                    Self:tmp 
                  })
                }} />
            )}

          
            <div style={{
              display: 'flex',
              width:'100%',
              justifyContent:'space-between',
              marginTop:20
            }}>
              <Button size='small' color='warning' onClick={()=>this.Save()}>Сохранить</Button>
              {props.ID_OBJECT==null || props.ID_OBJECT==0?null:
              <Button size='small' color='error' onClick={()=>this.Delete()}>Удалить</Button>}
              
             
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:320px;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg,fr)=>dispatch(getDB_Data_lazy(arg,fr)),
        getDB_Data:(arg,SDF)=>dispatch(getDB_Data(arg,SDF)),
        getVARS:(arg)=>dispatch(getVARS(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      BONUS_LVL:store.page.BONUS_LVL,
      COMPANYS:store.page.COMPANYS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_BonusDepth)