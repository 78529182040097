import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import {  getDB_Data_lazy,getWidgets } from '../../../actions/PageActions';
import Input, { Label } from '../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import DataWindow from '../Settings/Services/DataWindow';



const fields = [
  {
    title:'Название',
    name:'TITLE',
  }
]

class POP_widget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          Self:{
            SERVICES:[]
          }
        }
        this.input={}
        this.previosValue = null
        
    }
    componentDidMount(){
      this.getSelf()
    }

    getSelf(){
      if(this.props.ID_OBJECT==null) return

      let Self = this.props.WIDGETS.find(w=>w.ID==this.props.ID_OBJECT)
      Object.keys(Self).forEach(key=>{
        try{
          this.input[key].value=Self[key]
        }catch(e){}
      })

      this.setState({Self})

     
    }

    Delete(){
      GF.API_task({
        operation:'deleteSome',
        Table:'WIDGETS',
        ID:this.props.ID_OBJECT
      }).then(data => {
        this.props.getWidgets()
          this.props.closePopUp()
      })
    }

    Update(){
      if(!GF.checkReqvireds(this.input)) return

      let reqvest = {
        operation:'createUpdateWIDGET',
        data:{}
      }


      if(this.props.ID_OBJECT!=null)
      reqvest.ID = this.props.ID_OBJECT

      Object.keys(this.input).forEach(key=>{
        reqvest.data[key]=this.input[key].value
      })
      
      reqvest.data.SERVICES = JSON.stringify(this.state.Self.SERVICES.map(s=>s.id))

      GF.API_task(reqvest).then(data => {
          this.props.getWidgets()
          this.props.closePopUp()
      })
    }


    ChooseServices(selected){
      let serv_tmp = this.state.Self.SERVICES.slice(0)
      serv_tmp = serv_tmp.concat(selected)
      this.setState({Self:{
        ...this.state.Self,
        SERVICES:serv_tmp
      }})
      
      this.props.closePopUp()
    }
   
  
    render (){
        const {Self} = this.state
        const {SERVICES_BONUS_TYPES,VARS,ID_OBJECT} = this.props
        
        return (
          <POP> 
            <h4>Конструктор виджета</h4>

            <Label>Статус</Label>
            <select ref={ref=>this.input.IS_ACTIVE=ref} className='form-control mb-2'>
            <option value={1}>Активен</option>
            <option value={0}>Скрыт</option>
              
            </select>
           
            {fields.map(f=>
               <Input Ref={this.input} {...f}/>
            )}

            <Label>Выбранные услуги</Label>
            <ScrollView className='mb-3'>
              <div className='btn btn-sm mx-auto d-block  btn-light' onClick={()=>
                this.props.openPopUp(<DataWindow callBack={selected=>this.ChooseServices(selected)} notAllowedInFilter={Self.SERVICES.map(serv=>{ return{YC_ID:serv.id}})}/>)
              }>Добавить</div>
              {Self.SERVICES.map((s,i)=>
                s.id==null?
                <ScItem>
                  <p style={{color:'red'}}>Услуга не найдена в YClients</p>
                  <b onClick={()=>{
                    let tmp_serv = Self.SERVICES.slice(0)
                    tmp_serv.splice(i,1)
                    this.setState({
                      Self:{
                        ...Self,
                        SERVICES:tmp_serv
                      }
                    })
                  }}>х</b>
                </ScItem>:

                <ScItem>
                  <p>{s.title}</p>
                  <b onClick={()=>{
                    let tmp_serv = Self.SERVICES.slice(0)
                    tmp_serv.splice(i,1)
                    this.setState({
                      Self:{
                        ...Self,
                        SERVICES:tmp_serv
                      }
                    })
                  }}>х</b>
                </ScItem>
              )}
            </ScrollView>

            <div>
              {ID_OBJECT==null?null:
              <div className='btn btn-sm  btn-danger float-start' onClick={()=>this.Delete()}>Удалить</div>
              }
              <div className='btn btn-sm  btn-light float-end' onClick={()=>this.Update()}>Сохранить</div>
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:500px;
  `

  const ScrollView = styled.div`
  min-height: 30px;
  border-radius: 10px;
  width:100%;

  background: #f9fafb;
  overflow: auto;
  max-height: 350px;
  `

  const ScItem = styled.div`
  min-height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 14px;
  padding: 20px;
  display: inline-table;
  width:100%;
  border-bottom: 1px solid rgb(241, 241, 241);
  & p{
    width:calc(100% - 20px);
    display:table-cell;
    vertical-align: middle;
  }
  & b{
   width:20px;
   display:table-cell;
   text-align:center;
   font-weight: 300;
   opacity:0;
   transition:.2s;
   vertical-align: middle;
   cursor:pointer;
  }
  &:hover b{
    opacity:1;
  }
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getWidgets:(arg)=>dispatch(getWidgets(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      WIDGETS:store.page.WIDGETS,
      YC:store.page.YC,
      VARS:store.page.VARS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_widget)