
import { Avatar, Chip, Divider, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { getSelfBonusLog } from '../../../../actions/PageActions';
import { openPopUp } from '../../../../actions/PopUpActions';
import YC from '../../../../backend/YC';
import { Pallet } from '../../../../constants/Constants';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import { Label } from '../../../components/moduls/Input';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           filter:null,
        }
        this.input=[]
    }

    componentDidMount(){
      const {props,state} = this
      props.getSelfBonusLog()
    }

    renderOrder(order,order_i){
      const {props,state} = this
      
      return(
        <>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={!!order.staff && order.staff.name} src={!!order.staff && order.staff.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={<Chip onClick={()=>{
                window.open('https://n538372.yclients.com/company:'+order.Company.ID+'?o=m'+order.staff.id+'#1', '_blank');
                
              }}
                avatar={<Avatar  src={global.URL_host+'img/' + order.Company.IMAGES_URL[0]}/>}
                label={<><small>{order.Company.TITLE.replace('Rich Nails ','')+' / '}</small> {!!order.staff && order.staff.name}</>}
              />}
              style={{
                color:Pallet.White
              }}
              secondary={
                <React.Fragment>
                  {order.allCost==0?null:
                    <Typography
                      component="span"
                      variant="body2"
                      className='mb-3 mt-2 d-block'
                      color={Pallet.Primary.Accent}
                    ><b>Услуги {GF.makeCoast(order.allCost)}руб</b>
                    </Typography>
                  }
                  {order.services.map(serv=>
                    <div style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:window.innerWidth<1200?'space-between':"flex-start",
                      alignItems:window.innerWidth<1200?'center':"flex-start",
                      marginBottom:10,
                    }}>
                      <div className='pe-1'>
                        <Typography>{serv.title}</Typography>
                        <p className='m-0'>{GF.makeCoast(serv.cost)}руб</p>
                      </div>
                      {!!serv.bonus && <Chip size='small' label={'+'+(serv.bonus.VALUE+'Б')} color='success'/>}
                    </div>
                  )}
                  <small className='float-end'>{order.date}</small>
                </React.Fragment>
              }
            />
          </ListItem>
          {order_i!=props.YC_ORDERS.length-1 ? <Divider variant="inset" component="li" style={{
            marginRight:20
          }} /> :null }
        </>
      )
    }

  
    render (){
        const {props,state} = this
        let orders_future=[]
        let orders_past=[]

        if(props.YC_ORDERS!=null){
          orders_future = props.YC_ORDERS.filter(order=>order.dateTime>new Date())
          orders_past = props.YC_ORDERS.filter(order=>order.dateTime<=new Date())
        }
        debugger
        return (
          <MainView>
            <h3>Мои записи</h3>
            {props.YC_ORDERS==null?
            <LinearProgress color="warning" />:
            (<>
            {orders_future.length==0?null:<>
              <h5>Предстоящие записи</h5>
              <List sx={{ width: '100%', borderRadius:1, bgcolor: Pallet.Gray }}>
                  {orders_future.map((order,order_i)=>
                  this.renderOrder(order,order_i)
                  )}

              </List>
            </>}

              <h5>Прошедшие записи</h5>
              <List sx={{ width: '100%', borderRadius:1, bgcolor: Pallet.Gray }}>
                {orders_past.length==0?<small>Нет записей</small>:null}
                {orders_past.filter(order=>order.dateTime<=new Date()).map((order,order_i)=>
                 this.renderOrder(order,order_i)
                )}

              </List>
            </>)}
          </MainView>
        )
    }
}

const MainView = styled.div`
margin-bottom:150px;
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getSelfBonusLog:(arg)=>dispatch(getSelfBonusLog(arg)),
    }
}


const mapStateToProps = (store) => {
   
    return {
      YC_ORDERS:store.page.YC_ORDERS,
      user: store.user.data,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Index);

