import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { loadUserData_FromStorage,loginIn } from '../actions/UserAct';
import { getVARS,getDB_Data,getDB_Data_lazy,getYCservices,getSHOP_ITEMS,getWidgets, getSelfBonusLog, getUsers, setFilial } from '../actions/PageActions';
import Login from "./Login"
import { ToastProvider } from 'react-toast-notifications'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom"; 

import { withCookies } from 'react-cookie';
import Main from './Main';
import {GlobalFunctions as GF} from "../GlobalFunctions"
import "../global"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import logo from '../design/logo.png'
import { createTheme, CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { Pallet } from '../constants/Constants';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

class App extends Component {
  constructor(props) {
    super(props)

    const { cookies,dispatch } = props;
    
    this.state={
      appLoaded:false
    }

    

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }

    global.afterLoginned = async () => {
      let DataTables = ['COMPANYS','SELECTED_SERVICES','SHOP_CATEGORIS','SERVICES_BONUS_TYPES','SHOP_ITEMS','YC']
      this.DataShedul = [
        ()=>this.props.getDB_Data('getBONUS_LVL','BONUS_LVL'),
        ()=>this.props.getDB_Data_lazy('COMPANYS','IMAGES_URL'),
        ()=>this.props.getDB_Data_lazy('BONUS_LOG_TYPES'),
          ()=>this.props.getDB_Data('getSELECTED_SERVICES','SELECTED_SERVICES'),
          ()=>this.props.getDB_Data_lazy('SHOP_CATEGORIS'),
          ()=>this.props.getDB_Data_lazy('SERVICES_BONUS_TYPES'),
          ()=>this.props.getSHOP_ITEMS(),
          ()=>this.props.getYCservices(),
        ]
      

      //тут описывается то, что будет запущено после авторизации
      try{
        this.props.getVARS()
        
        switch(this.props.user.data.ROLE){
          case'ADMN':
          this.DataShedul=this.DataShedul.concat([
            
            ()=>this.props.getUsers(),
            ()=>this.props.getDB_Data('getCLIENTS','CLIENTS'),
            ()=>this.props.getWidgets(),
          ])
          break;
          case'MNGR':
          this.DataShedul=this.DataShedul.concat([
            
            ()=>this.props.getDB_Data('getCLIENTS','CLIENTS'),
          ])
          break;
          case'FRCH':
          this.DataShedul=this.DataShedul.concat([
            
            ()=>this.props.getDB_Data('getCLIENTS','CLIENTS'),
          ])
          break;
          case'CUST':
          DataTables = DataTables.concat(['BONUS_LOG','YC_ORDERS'])
          this.DataShedul=this.DataShedul.concat([
            ()=>this.props.getSelfBonusLog(),
            ()=>this.props.getDB_Data('getSelfCustomer','SELF_CUSTOMER'),
          ])
          break;
        }
        
        
      }catch(e){}


      this.DataShedul.forEach((ds,i)=>{
        setTimeout(()=>ds(),10*i)
      })

      let i = 0;
      while(i<DataTables.length){
        let d = DataTables[i]
        while(this.props.page[d]==null){
          await GF.WaitingTimer(100)
        }
        await GF.WaitingTimer(100)
       
        i++
      }

        
        setTimeout(()=>{
          
          let selectedFilial = cookies.get("selectedFilial") || this.props.page.COMPANYS[0].ID
          dispatch(setFilial(selectedFilial))

          this.setState({appLoaded:true})
        },1000)
    }

    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()
    



  }

  componentDidMount(){
   

  }


  

  render() {
    const { user,PopUps} = this.props
    let content = null
    const {appLoaded} = this.state
  
   
    if (user.isLogined == null) {
      content =  null
    } else if (user.isLogined)
      content = !appLoaded?<div>
        <LinearProgress color="inherit" />
        <Logo src={logo}/>
        <p className='text-center'>загружается</p>
      </div>:(
        <div key="rootView"> 
          <Router key="mainRouter" >
            <Switch>
              

              <Route path='/'>
              
                <Main/>
              </Route>
            </Switch>
            
          </Router>
        </div>
      )
    else 
    content = (
        <Router key="mainRouter" >
            <Switch>

              <Route path='/'>
                <Login />
              </Route>
            </Switch>
            
          </Router>
    )
    
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <ToastProvider>
        <CssBaseline/>
        <ThemeProvider theme={darkTheme}>
        {PopUps.length>0?<BlurBg visible={true}/>:<BlurBg visible={false}/>}

        {PopUps.map(popUp=>{
          return popUp
        })}
        <div style={{
          background:Pallet.Black,
          minHeight:'100vh',
          width:'100vw',
          overflowX:'hidden',
          overflowY:'auto'
        }}>
          
          {content}
          
        </div>
        </ThemeProvider>
      </ToastProvider>
      </LocalizationProvider>
      
    )

  }
}

const BlurBg = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: rgba(0,0,0,0);
    backdrop-filter:blur(3px);
    opacity:${props=>props.visible?1:0};
    transition:opacity .3s;
    width: 100vw;
    z-index:${props=>props.visible?20:-9999};
`

const Logo = styled.img`
width:300px;
margin:auto;
margin-top:20vh;
display:block;
`



const mapDispatchToProps = (dispatch) => {
  return {
    dispatch:(arg)=>dispatch(arg),
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)), 
    loginIn: (arg) => dispatch(loginIn(arg)), 
    getVARS: (arg) => dispatch(getVARS(arg)), 
    getUsers:(arg) => dispatch(getUsers(arg)), 

    getYCservices: (arg) => dispatch(getYCservices(arg)),
    getSelfBonusLog: (arg) => dispatch(getSelfBonusLog(arg)),
    getSHOP_ITEMS: (arg) => dispatch(getSHOP_ITEMS(arg)),
    getWidgets: (arg) => dispatch(getWidgets(arg)),
    getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    page:store.page,
    PopUps: store.popups.windows
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(App));

