
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import Table from '../../components/Table';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import {  getSHOP_ITEMS} from '../../../actions/PageActions';

import POP_TovarChange from '../Settings/BayServices/POP_TovarChange';
import POP_widget from './POP_widget';

const Rows = [
    {
        title:'ID',
        key:'ID',
    },
    {
      title:'Название',
      key:'TITLE',
    },
    {
      title:'Ссылка',
      key:'URL_KEY',
    },
    {
      title:'Статус',
      key:'IS_ACTIVE',
    }
    
]


class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            filter:null
        }
        this.checkBoxs=[]
        this.input=[]
    }



    Delete(ID){
        return new Promise((resolve,reject)=>{
          let data = {
            operation:'deleteSome',
            Table:'SHOP_ITEMS',
            ID
          }
  
          
          
          GF.API_task(data).then(res=>{
            
            this.props.getSHOP_ITEMS()
            setTimeout(()=>resolve(),1)
            
          }).catch(()=>reject()) 
        })
        
      }


    render (){
        const {WIDGETS} = this.props
        const {filter} = this.state
        return (
           <MainView>
            <div>
            <Btn className='btn btn-sm btn-light me-2 float-start mb-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = true
              })
            }}>
                Выбрать все
            </Btn>
            <Btn className='btn btn-sm btn-light me-2 float-start mb-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = false
              })
            }}>
                Снять выделение
            </Btn>
            <Btn className='btn btn-sm btn-light me-2 float-start mb-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach(async (key,index)=>{
                if(this.checkBoxs[key].checked){
                  this.checkBoxs[key].checked=false
                  this.Delete(key)
                  delete(this.checkBoxs[key])
                }
              })
              
            }}>
                Удалить выбранные
            </Btn>
            <Btn className='btn btn-sm btn-light me-2 float-start mb-2' onClick={()=>{
               this.props.openPopUp(<POP_widget/>)
            }}>
                Добавить
            </Btn>

            <Search placeholder='Поиск' className='form-control float-start d-block mb-2' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>
            </div>

            <br/>
            <Table
                className='mt-3'
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={WIDGETS.filter(a=> filter==null || a.TITLE.toUpperCase().indexOf(filter)!=-1  )}
                itemOnClick={({data,index})=>{
                    
                    this.props.openPopUp(<POP_widget ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.ID}</td>,
                    <td>{data.TITLE}</td>,
                    <td><a onClick={(e)=>e.stopPropagation()} target='blank' href={`https://quicksearch.richnails.club/key/${data.URL_KEY}`}>{data.URL_KEY}</a></td>,
                    <td>{data.IS_ACTIVE==1?'Активен':'Скрыт'}</td>,
                ]}/>
           </MainView>
        )
    }
}

const MainView = styled.div`

`
const Btn = styled.div`

`

const Search = styled.input`
width:200px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getSHOP_ITEMS:(arg)=>dispatch(getSHOP_ITEMS(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        WIDGETS:store.page.WIDGETS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index);

