import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getDB_Data,getVARS } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import { Button } from '@mui/material';

const fields = [
  {
    title:'Название (3 символа)',
    name:'NAME',
    maxlength:3,
    
    onChange:(e)=>{
      debugger
      if(e.target.value.length>3){
        e.target.value=e.target.value.substr(0,3)
      }
    }
  },
  {
    title:'Описание',
    name:'DESCR',
  },
  {
    title:'Значение',
    name:'VALUE'
  }
]

class POP_FixedBonus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.input={}
        this.previosValue = null
        
    }
    componentDidMount(){
      
      if(this.props.BONUS_TYPE != null)
      this.getSelf()
    }

    getSelf(){
      
      let SERVICES_BONUS_TYPES = this.props.SERVICES_BONUS_TYPES.find(type=>type.BONUS_TYPE==this.props.BONUS_TYPE)
      SERVICES_BONUS_TYPES.VALUE = this.props.VARS[this.props.BONUS_TYPE].value
      SERVICES_BONUS_TYPES.NAME = SERVICES_BONUS_TYPES.BONUS_TYPE

      this.data = SERVICES_BONUS_TYPES
      
      Object.keys(SERVICES_BONUS_TYPES).forEach(key=>{
        try{
          this.input[key].value=SERVICES_BONUS_TYPES[key]
        }catch(e){}
      })

     
    }

   
    Delete(){
      GF.API_task({
        operation:'deleteFixedBonus',
        BONUS_TYPE:this.data.NAME
      }).then(data => {
        this.props.getDB_Data('getSELECTED_SERVICES','SELECTED_SERVICES')
        this.props.getDB_Data_lazy('SERVICES_BONUS_TYPES')
        this.props.getVARS()
        this.props.closePopUp()
      })
    }

    CreateUpdate(){
      if(!GF.checkReqvireds(this.input)) return

      let reqvest = {
        data:{}
      }

      if(this.props.BONUS_TYPE!=null){
        reqvest.BONUS_TYPE = this.data.NAME
        reqvest.operation='updateFixedBonus'
      }else{
        reqvest.operation='createFixedBonus'
      }

      Object.keys(this.input).forEach(key=>{
        reqvest.data[key]=this.input[key].value
      })

      
      GF.API_task(reqvest).then(data => {
        this.props.getDB_Data('getSELECTED_SERVICES','SELECTED_SERVICES')
        this.props.getDB_Data_lazy('SERVICES_BONUS_TYPES')
        this.props.getVARS()
        this.props.closePopUp()
        
        
      })
    }

   
  
    render (){
        const {isDisabled,service_title} = this.state
        const {SERVICES_BONUS_TYPES,VARS} = this.props
        return (
          <POP> 
            {this.props.BONUS_TYPE == null ? 
            <h4>Новое значение</h4> :
            <h4>Настройка значения</h4>}
            
            
          
            {fields.map(f=>
               <Input Ref={this.input} {...f} />
            )}
            <div>
              
              <Button size='small' className='float-end' onClick={()=>this.CreateUpdate()}>Сохранить</Button>
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:320px;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getDB_Data:(arg,SDF)=>dispatch(getDB_Data(arg,SDF)),
        getVARS:(arg)=>dispatch(getVARS(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      SERVICES_BONUS_TYPES:store.page.SERVICES_BONUS_TYPES,
      YC:store.page.YC,
      VARS:store.page.VARS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_FixedBonus)