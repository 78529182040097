
import { Avatar, Button, Chip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { Pallet } from '../../../constants/Constants';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import POP_widget from '../QuicksearchDev/POP_widget';
import LvlUpContainer from './LvlUpContainer';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }


    render (){
        const {state,props} = this
        const {CLIENTS,SELECTED_SERVICES,user,SELF_CUSTOMER,BONUS_LOG,YC,SHOP_ITEMS} = this.props
        let ALL_BALS = 0
        CLIENTS?.forEach(c=>{
            ALL_BALS+=c.GLOBAL_COINS
        })

        if(user.ROLE=='ADMN' || user.ROLE=='MNGR' || user.ROLE=='FRCH')
        return (
           <MainView>
            {user.ROLE!='ADMN'?null:
            <Button className='mb-3 d-block' color='warning'
                onClick={()=>this.props.openPopUp(<POP_widget/>)}>Создать URL быстрого виджета</Button>
        }
               <div className='row'>
               <div className='col-lg-4 col-md-4 mb-4'>
                        <Card>
                            <h3>{SELECTED_SERVICES.length}</h3>
                            <p>{GF.okonchanie(SELECTED_SERVICES.length,'Услуга','Услуги','Услуг')} в бонусной системе</p>
                        </Card>
                    </div>
                    <div className='col-lg-4 col-md-4 mb-4'>
                        <Card>
                            <h3>{GF.makeCoast(ALL_BALS)}</h3>
                            <p>Начисленно баллов</p>
                        </Card>
                    </div>
                    <div className='col-lg-4 col-md-4 mb-4'>
                        <Card>
                            <h3>{GF.makeCoast(CLIENTS.length)}</h3>
                            <p>{GF.okonchanie(CLIENTS.length,'Клиент','Клиента','Клиентов')}</p>
                        </Card>
                    </div>
               </div>
           </MainView>
        )

        if(user.ROLE=='DEVP')
        return (
            <MainView>
                <Chip label='Аккаунт разработчика сайта'/>
                <br/>
                <Button className='mt-3 d-block' color='warning'
                onClick={()=>this.props.openPopUp(<POP_widget/>)}>Создать URL быстрого виджета</Button>
            </MainView>
         )
            
        if(user.ROLE=='CUST'){
            let nextLvl = props.BONUS_LVL[SELF_CUSTOMER.LVL+1]
        return (
           <MainView showYCbutton>
                <LvlUpContainer/>
               <div className='row'>
                    <div className='col-lg-4 col-md-4 mb-4'>
                        <Card style={{
                            position:'relative',
                            height:'100%',
                            overflow:'hidden'
                        }}>
                            <h5 className='pt-3'>Уровень: {SELF_CUSTOMER.LVL}</h5>
                            <p>Кешбек: {SELF_CUSTOMER.CASHBACK_SUZE*100}%</p>
                            {nextLvl==null?null:<>
                                <small style={{
                                    position: 'absolute',
                                    width: '100%',
                                    left: 0,
                                    bottom: 12,
                                }}>До следующего уровня: {GF.makeCoast(nextLvl.CASH_COUNT - SELF_CUSTOMER.ALL_COAST)} руб</small>
                                <div style={{
                                    position: 'absolute',
                                    width: '100%',
                                    left: 0,
                                    height:6,
                                    background:'rgb(55, 55, 55)',
                                    bottom: 0,
                                }}/>
                                <div style={{
                                    position: 'absolute',
                                    width: SELF_CUSTOMER.ALL_COAST/nextLvl.CASH_COUNT*100+'%',
                                    left: 0,
                                    height:6,
                                    background:'rgba(240, 156, 96, 1)',
                                    bottom: 0,
                                }}/>
                            </>}
                        </Card>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 mb-4'>
                        <Card style={{
                            position:'relative',
                            height:'100%'
                        }}>
                            <h5 className='pt-3'>{GF.makeCoast(SELF_CUSTOMER.BONUS_COINS)} <small>Б</small></h5>
                            <p>Баллов на счету</p>
                        </Card>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 mb-4'>
                        <Card style={{
                            position:'relative',
                            height:'100%'
                        }}>
                            <h5 className='pt-3'>{GF.makeCoast(SELF_CUSTOMER.GLOBAL_COINS)} <small>Б</small></h5>
                            <p>Всего баллов получено</p>
                        </Card>
                    </div>
               </div>


               <div className='row mb-5 pb-5'>
                    <div className='col-lg-12'>
                        <Card>
                            <p className='text-start'><b>Последние операции по счету</b></p>
                           
                                {BONUS_LOG.map((bl,i)=>{
                                    let obj = null
                                    if(bl.TYPE=='E') return null
                                    if(bl.TYPE.length==2){
                                        obj=bl
                                    }else{
                                    
                                    if(bl.TYPE=='U'){
                                        obj={}
                                        obj.TITLE = bl.YC_TITLE
                                        obj.VALUE = bl.VALUE
                                    
                                    }else{
                                        try{
                                            obj = SHOP_ITEMS.find(si=>si.ID==bl.ID_SERVICE)
                                            obj.VALUE = -obj.COAST
                                            obj.YC_ID = obj.YC_ID
                                        }catch(e){
                                            obj=null
                                        }
                                    }

                                    if(obj==null)
                                    obj={TITLE:'-'}

                                    if(bl.TYPE=='M'){
                                        obj={
                                          TITLE:'',
                                          VALUE:(bl.VALUE>0?'+':'') + bl.VALUE
                                        }
                                      }
                                      if(bl.TYPE=='G'){
                                        obj={
                                          TITLE:'',
                                          VALUE:(bl.VALUE>0?'+':'') + bl.VALUE
                                        }
                                      }
                                    
                                    }
                                    return(
                                        <BalLog>
                                            <div style={{
                                                display:'flex',
                                                justifyContent:'space-between',
                                                alignItems:'center',
                                            }}>
                                                <div>
                                                    {bl.order==null || bl.order.staff==null?null:
                                                    <Chip size='small' className='mb-2' onClick={()=>{
                                                        window.open('https://n538372.yclients.com/company:'+bl.ID_COMPANY+'?o=m'+bl.order.staff.id+'#1', '_blank')
                                                    }}
                                                        avatar={<Avatar alt={!!bl.order.staff && bl.order.staff.name} src={!!bl.order.staff && bl.order.staff.avatar} />}
                                                        label={<><small>{bl.COMPANY_TITLE.replace('Rich Nails ','')+' / '}</small> {!!bl.order.staff && bl.order.staff.name}</>}
                                                    />}
                                                    <Label>{GF.makeNormalDate(bl.DATE_CREATE,'dd.mm.yyyy H:M')}</Label>
                                                    <Label>{bl.TYPE_DESCR}</Label>
                                                    <p style={{color:Pallet.Primary.Accent}}>{obj.TITLE}</p>
                                                </div>
                                                <div>
                                                    {obj.VALUE==null?null:
                                                    <Chip size='small' label={(obj.VALUE>0?'+':'')+(GF.makeCoast(obj.VALUE)+'Б')} color={obj.VALUE>0?'success':'warning'} className='float-end'/>}
                                                </div>

                                            </div>

                                            <hr/>
                                        </BalLog>   
                                    )
                                })}
                           
                        </Card>
                    </div>
               </div>
           </MainView>
        )}
    }
}

const MainView = styled.div`

`


const BalLog = styled.div`
text-align:left;
& p{
  font-size:13px;
  line-height:14px;
}
& b{
  margin-top: -15px;
margin-bottom: 20px;
display: block;
}
`


const Card = styled.div`
background:${Pallet.Gray};
color:${Pallet.White};
border-radius:5px;

padding:20px;
text-align:center;
& h3{
margin-bottom:0px;
margin-top:15px;
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SELECTED_SERVICES:store.page.SELECTED_SERVICES,
        BONUS_LVL:store.page.BONUS_LVL,
        CLIENTS:store.page.CLIENTS,
        BONUS_LOG:store.page.BONUS_LOG,
        YC:store.page.YC,
        user: store.user.data,
        SHOP_ITEMS:store.page.SHOP_ITEMS,
        SELF_CUSTOMER:store.page.SELF_CUSTOMER,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Index);

