import { GlobalFunctions as GF } from "../../GlobalFunctions"

const YC = {
    getClientOrders:(client_yc_id)=>{
        return GF.API_task({
            operation:'getClientOrders',
            client_yc_id
        })
    }
}

export default YC
