import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy} from '../../../../actions/PageActions';
import { GlobalFunctions } from '../../../../GlobalFunctions';
import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../../components/Table';
import POP_Company from './POP_Company';



const Rows = [
  {
    title:'',
    key:'PHOTO',
    width:100
  },
  {
    title:'Название',
    key:'TITLE',
  },
  
  {
    title:'Адрес',
    key:'ADDRESS',
  },
  {
    title:'Как пройти',
    key:'HOW_TO_WALK',
  },
  {
    title:'Телефон',
    key:'PHONE',
  },
]

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,

          pop_filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

   

    render (){
      const {filter,Data} = this.state
      const {props} = this
        return (
        <MainView>
          
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={false}
                data={props.COMPANYS}
                itemOnClick={({data,index})=>{
                  if(data.IS_SUSTEM!=1)
                    props.openPopUp(<POP_Company ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>
                      <img style={{
                        background:'black',
                        objectFit:'cover',
                        height:100,
                        width:100,
                        borderRadius:5
                      }} src={data.IMAGES_URL!=null && data.IMAGES_URL[0]!=null ? global.URL_host+'img/'+data.IMAGES_URL[0] : null}/>
                    </td>,
                    <td>{data.TITLE}</td>,
                    <td>{data.ADDRESS}</td>,
                    <td>{data.HOW_TO_WALK}</td>,
                    <td>{GlobalFunctions.phoneFormatter(data.PHONE)}</td>,
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`
margin-top:-10px;
`



const Search = styled.input`
width:200px;
`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      COMPANYS:store.page.COMPANYS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index)

