import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getDB_Data,getVARS } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import { Styles } from '../../../../constants/Constants';
import { Button } from '@mui/material';

const fields = [
  {
    title:'Описание',
    name:'DESCR',
    maxlength:50,
    multyline:true,
    
    onChange:(e)=>{
      if(e.target.value.length>50){
        e.target.value=e.target.value.substr(0,50)
      }
    }
  },
]

class POP_BonusKategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          Self:{}
        }
        this.input={}
        this.previosValue = null
        
    }
    componentDidMount(){
      
      if(this.props.ID_OBJECT != null)
      this.getSelf()
    }

    getSelf(){
      
      let BONUS_LOG_TYPE = this.props.BONUS_LOG_TYPES.find(type=>type.TYPE==this.props.ID_OBJECT)
      this.setState({Self:JSON.parse(JSON.stringify(BONUS_LOG_TYPE))})
    
      Object.keys(BONUS_LOG_TYPE).forEach(key=>{
        try{
          this.input[key].value=BONUS_LOG_TYPE[key]
        }catch(e){}
      })

     
    }

   
    Delete(){
      GF.API_task({
        operation:'deleteBonusKATEG',
        ID_OBJECT:this.props.ID_OBJECT
      }).then(data => {
        this.props.getDB_Data_lazy('BONUS_LOG_TYPES')
        this.props.closePopUp()
      })
    }

    CreateUpdate(){
      if(!GF.checkReqvireds(this.input)) return

      let reqvest = {
        data:{}
      }

      if(this.props.ID_OBJECT!=null){
        reqvest.ID_OBJECT = this.props.ID_OBJECT
        reqvest.operation='updateBonusKATEG'
      }else{
        reqvest.operation='createBonusKATEG'
      }

      Object.keys(this.input).forEach(key=>{
        reqvest.data[key]=this.input[key].value
      })
      
      GF.API_task(reqvest).then(data => {
        this.props.getDB_Data_lazy('BONUS_LOG_TYPES')
        this.props.closePopUp()
      })
    }

   
  
    render (){
        const {isDisabled,service_title,Self} = this.state
        const {SERVICES_BONUS_TYPES,VARS} = this.props
        return (
          <POP> 
            {this.props.ID_OBJECT == null ? 
            <h4>Новая категория</h4> :
            <h4>Настройка категории</h4>}
          
            {fields.map(f=>
               <Input Ref={this.input} {...f} />
            )}
            <div style={Styles.inline}>
              {this.props.ID_OBJECT==null?null:
              <Button color='error' size='small' onClick={()=>this.Delete()}>Удалить</Button>
              }
              <Button size='small'onClick={()=>this.CreateUpdate()}>Сохранить</Button>
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:320px;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getDB_Data:(arg,SDF)=>dispatch(getDB_Data(arg,SDF)),
        getVARS:(arg)=>dispatch(getVARS(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      BONUS_LOG_TYPES:store.page.BONUS_LOG_TYPES,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_BonusKategory)