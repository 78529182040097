import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';



class DataWindow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          pop_filter:{
            usl_type:'Usl'
          },
          selected:[],
          showAll:false
        }
        this.input={
            dataWindow:[]
        }
    }

   
  
   
  
    render (){
        const {pop_filter,selected,showAll} = this.state
        const {SELECTED_SERVICES,COMPANYS,notAllowedInFilter} = this.props

        let filteredServices = []

        let data = []
        
        if(notAllowedInFilter!=null)
          this.props.YC.services.forEach(s=>{
                if(notAllowedInFilter.findIndex(selec => selec.YC_ID == s.id || selec.id == s.id)==-1)
                  data.push(s)
          })
        else data = this.props.YC.services
        
        if(pop_filter!=null){
          if(pop_filter.title!=null){
            data = data.filter(serv => serv.title.toUpperCase().indexOf(pop_filter.title)!=-1)
          }
    
          if(pop_filter.company!=null){
            data = data.filter(serv => serv.companys.findIndex(com_id => com_id ==  pop_filter.company)!=-1)
          }

          if(pop_filter.usl_type!=null){
            data = data.filter(serv => 
              serv.category_id==7773718 && pop_filter.usl_type=='Akc' ||
              serv.category_id!=7773718 && pop_filter.usl_type=='Usl'
            )
          }
        }
        
        filteredServices = data.slice(0)

        let isMoreThen40 = false
        
        if(data.length>=40 && !showAll){
            data= data.splice(0,40)
            isMoreThen40=true
        }
        

        let onClick=(serv)=>{
            
            let selected_tmp = this.state.selected.slice(0)
            let index = selected_tmp.findIndex(s=>s.id==serv.id)
            if(index ==-1)//если не выбрана услуга
              selected_tmp.push(serv)
            else
              selected_tmp.splice(index,1)
            
            this.setState({selected:selected_tmp})
        }
        
        
        if(data.length==0){
          data = <small className='text-center'>Нет доступных для синхронизации услуг</small>
        } else
          data = [...data.map(serv=>
              <Services onClick={()=>onClick(serv)} selected={selected.findIndex(s=>s.id==serv.id)!=-1}>
              <p style={{fontSize:13}} className='mb-0 mt-4 '>{serv.title} <small className='text-danger'>{serv.category_id!=7773718?null:'Акция'}</small></p>
              <Label>{GF.makeCoast(serv.price_max)}руб</Label>
              {serv.companys.map(com_id=>
                <small className='me-2 mt-0 bg-light p-1 rounded'>{this.props.COMPANYS.find(c=>c.ID==com_id).TITLE}</small>  
              )}
              </Services>
            )
            ,isMoreThen40?<p className='mt-5 mb-5' onClick={()=>{
                this.setState({showAll:true})
            }}>Показать все</p>:null
          ]

        return (
            <POP_DataWindow> 
            <h5>Услуги YClients</h5>
            <div className='row'>
              <div className='col-4'>
                {form_fields.map(input => 
                  <Input    
                  onKeyUp={(e)=>{
                    this.setState({pop_filter:{
                        ...pop_filter,
                        title:e.target.value==''?null:e.target.value.toUpperCase()
                     }})
                  }}
                  {...input}
                  Ref={this.input.dataWindow}/>
                )} 
              </div>
              <div className='col-4'>
                <Label for='company' >Доступная в филиале</Label>
                <select id='company' className='form-control' onChange={(e)=>{
                   this.setState({pop_filter:{
                       ...pop_filter,
                       company:e.target.value==-1?null:e.target.value
                    }})
                  }}>
                  <option value={-1} >Любом</option>
                  {COMPANYS.map(data=>
                    <option value={data.ID}>{data.TITLE}</option>
                  )}
                </select>
              </div>

              <div className='col-4'>
                <Label for='company' >Тип услуги</Label>
                <select id='company' className='form-control' onChange={(e)=>{
                   this.setState({pop_filter:{
                       ...pop_filter,
                       usl_type:e.target.value=='All'?null:e.target.value
                    }})
                  }}>
                  <option value='All' >Любой</option>
                  <option value='Usl' selected>Услуга</option>
                  <option value='Akc' >Акция</option>
                </select>
              </div>
            </div>
            <hr className='mb-0'/>
  
            <ScrollView>
                {data}
            </ScrollView>
            <hr style={{marginTop:0}}/>
            
            <div className='btn btn-sm  btn-light float-end' onClick={()=>this.setState({selected:filteredServices})}>Выбрать все</div>
            <div className='btn btn-sm  btn-light float-end me-2' onClick={()=>this.setState({selected:[]})}>Отменить выделение</div>

            <div className='btn btn-sm  btn-primary float-start' onClick={()=>this.props.callBack(selected)}>Продолжить</div>
            
            
           
        </POP_DataWindow>
        )
    }
  }

  const POP_DataWindow = styled.div`
  width:500px;
  `

  const ScrollView = styled.div`
  overflow-y:auto;
  max-height:60vh;
  min-height:300px;
  `

  const Services = styled.div`
  padding-left:5px;
  border-left: 5px solid white;
  transition:.3s;
  cursor:pointer;
  margin-left:-5px;
  ${props=>props.selected?`
  margin-left:0px;
  border-left: 5px solid #c2965c;
  `:null}
  `

  const form_fields=[
    {
      title:'Название',
      placeholder:'',
      name:'TITLE'
    },
    
  ]


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      YC:store.page.YC,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(DataWindow)