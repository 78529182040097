
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { closePopUp, openPopUp } from '../../../../actions/PopUpActions';

import { getDB_Data, getUsers } from '../../../../actions/PageActions';
import { Avatar, Button, Chip } from '@mui/material';
import Table from '../../../components/Table';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import POP_BonusDepth from './POP_BonusDepth';


const Rows = [
    {
      title:'Уровень',
      key:'LVL'
    },
    {
      title:'Сумма потраченых средств',
      key:'CASH_COUNT'
    },
    {
      title:'Кешбек',
      key:'CASHBACK_SUZE'
    },
    {
      title:'Скидка',
      key:'DISCOUNT_SIZE'
    },
  ]

class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
    }

    
    render (){
        const {filter,Data} = this.state
        const {props,state} = this
        let filteredData = props.USERS
          return (
          <MainView>
            <Button onClick={()=>{
              this.props.openPopUp(<POP_BonusDepth/>)
            }}>Новый уровень скидки</Button>
            <Table
                  className='mt-3'
                  rows={Rows}
                  canCheck={false}
                  data={props.BONUS_LVL}
                  itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_BonusDepth ID_OBJECT={data.LVL}/>)
                  }}
                  rowOnClick={({data,index})=>{
                      debugger
                  }}
                  renderItem={({data,index})=>
                  Rows.map(row=>{
                    switch(row.key){
                      case 'LVL': return <td><Chip label={data.LVL} /></td>
                      case 'CASH_COUNT': return <td>{GF.makeCoast(data.CASH_COUNT)}<small> руб</small></td>
                      case 'CASHBACK_SUZE': return <td>{GF.makeCoast(data.CASHBACK_SUZE*100)}%</td>
                      case 'DISCOUNT_SIZE': return <td>{GF.makeCoast(data.DISCOUNT_SIZE*100)}%</td>
                      default:return <td>{data[row.key]}</td>
                    }
                  })
                  }/>
  
            
          </MainView>
          )
      }
}

const MainView = styled.div`
margin-top:-10px;
`

const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getDB_Data:(arg,sd)=>dispatch(getDB_Data(arg,sd)),
        getUsers:(arg)=>dispatch(getUsers(arg)),
    }
}


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data,
        BONUS_LVL:store.page.BONUS_LVL,
        CLIENTS:store.page.CLIENTS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index);

