
  const initialState = {
    Arendators:[],
    COMPANYS:[],
    SELECTED_SERVICES:[],
    
    VARS:[],//статические переменные
    
    USERS:[],
    BONUS_LVL:[],
    YC:{
      services:[],
      categoris:[]
    },
    SERVICES_BONUS_TYPES:[],
    CLIENTS:[],
    WIDGETS:[],

    SHOP_ITEMS:[],
    SHOP_CATEGORIS:[],
    BONUS_LOG_TYPES:[],

    selectedFilial:null,

    //CUST
    SELF_CUSTOMER:{},
    BONUS_LOG:null,
    YC_ORDERS:null
  }
  
  export function pageReducer(state = initialState, action) {
    switch (action.type) {

      case 'get_bonus_log':
      return {
        ...state,
        BONUS_LOG:action.payload.BONUS_LOG,
        YC_ORDERS:action.payload.orders
      }


      case 'JUST_SET_VALUE':
        let temp_state={}
        Object.assign(temp_state,state)
        temp_state[action.key] = action.payload
        
        return temp_state

      case 'GET_YC_SERVICES_AND_CATEGORIES':
        
        return {
          ...state,
          YC:{
            ...state.YC,
            ...action.payload
          }
        }
      
      
      default:
        return state;
    }
  }