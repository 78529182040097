import moment from "moment"
import YC from "../backend/YC"
import {GlobalFunctions as GF} from "../GlobalFunctions"
import {store} from '../store/configureStore'


export function getUsers(arg) {
  
  return (dispatch) => {
    
    GF.API_task({
        operation:'getUsers'
    }).then(data => {
      
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'USERS'
      })
  })
    
  }
}

export function setFilial(arg) {
  
  return (dispatch) => {
    GF.setCookie('selectedFilial',arg)
    dispatch({
      type: 'JUST_SET_VALUE',
      payload:arg,
      key:'selectedFilial'
    })
    
  }
}

function JSONparse(data,key){
  data.forEach((d,index)=>{
    try{
      data[index][key] = JSON.parse(d[key])
    }catch(e){ data[index][key] = [] }
  })
  return data
}

export function getSelfBonusLog(){
  return (dispatch) => {
    
    GF.API_task({
      operation:'getSelfBonusLog'
    }).then(BONUS_LOG=>{
      
      YC.getClientOrders().then(result=>{
        let orders = []
        result.forEach((r,i)=>{
          let companyOrders = []
          if(r.ORDERS!=null){
            r.ORDERS=JSON.parse(r.ORDERS)
            if(r.ORDERS!=null && r.ORDERS.success==true){
              companyOrders=r.ORDERS.data
            }
          }
          
          delete(result[i].ORDERS)

          if(r.IMAGES_URL!=null){
            result[i].IMAGES_URL = JSON.parse(r.IMAGES_URL)
          }

          companyOrders.forEach(order=>{
            let date = order.date+''
            order.Company = result[i]
            order.allCost = 0
            order.dateTime =new Date(GF.makeNormalDate(date,'yyyy-mm-dd'))
            order.date = GF.makeNormalDate(date,'dd.mm.yyyy H:M')
            order.services.forEach((s,i)=>{
              order.allCost+=s.cost
              let indexLog = BONUS_LOG.findIndex(b=>
                b.YC_ID == s.id &&
                b.YC_ORDER_ID == order.id
              )
              if(indexLog!=-1){
                let bonysLog = {...BONUS_LOG[indexLog]}
              
                BONUS_LOG[indexLog] = {
                  ...BONUS_LOG[indexLog],
                  service:{...order.services[i]},
                  order:{
                    allCost:order.allCost*1,
                    date:order.date+'',
                    dateTime:order.dateTime,
                    staff:{...order.staff}
                  },
                }
                order.services[i].bonus = bonysLog
              }
            })
            orders.push(order)
          })
         
        })

        dispatch({
          type: 'get_bonus_log',
          payload:{
            orders,
            BONUS_LOG
          }
        })
        
      })

      
    })
    
  }
}


export function getDB_Data(operation,tableName,JSONparseKey,callBack){
    //console.error(operation)
    if(operation == "getCLIENTS"){
        //console.error(document.cookie)
        function getCookie(name) {
          let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined;
        }
        //console.error(getCookie("id"))
        
        return (dispatch) => {
        GF.API_task({
          operation,
          ID_USER:getCookie("id")
        }).then(data=>{
          
          if(Array.isArray(JSONparseKey)){
            
            JSONparseKey.forEach(key=>{
              if(key!=null)
                JSONparse(data,key)
            })
            
          }
          else
            if(JSONparseKey!=null)
              JSONparse(data,JSONparseKey)
          
          dispatch({
            type: 'JUST_SET_VALUE',
            key: tableName,
            payload:data
          })
          
          if(callBack!=null) callBack()
        })
        
      }
        
    }else{
    
      return (dispatch) => {
        GF.API_task({
          operation
        }).then(data=>{
          
          if(Array.isArray(JSONparseKey)){
            
            JSONparseKey.forEach(key=>{
              if(key!=null)
                JSONparse(data,key)
            })
            
          }
          else
            if(JSONparseKey!=null)
              JSONparse(data,JSONparseKey)
          
          dispatch({
            type: 'JUST_SET_VALUE',
            key: tableName,
            payload:data
          })
          
          if(callBack!=null) callBack()
        })
        
      }
    }
}

export function getDB_Data_lazy(tableName,JSONparseKey,callBack){
    if(tableName == "COMPANYS"){
        //console.error(document.cookie)
        function getCookie(name) {
          let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined;
        }
        //console.error(getCookie("id"))
        
        return (dispatch) => {
            GF.API_task({
              operation:'getDataFromTable',
              Table:tableName,
              ID_USER:getCookie("id")
            }).then(data=>{
              
              if(Array.isArray(JSONparseKey)){
                
                JSONparseKey.forEach(key=>{
                  if(key!=null)
                    JSONparse(data,key)
                })
                
              }
              else
                if(JSONparseKey!=null)
                  JSONparse(data,JSONparseKey)
              
              dispatch({
                type: 'JUST_SET_VALUE',
                key: tableName,
                payload:data
              })
            })
            
        }
    }else{
      return (dispatch) => {
        GF.API_task({
          operation:'getDataFromTable',
          Table:tableName
        }).then(data=>{
          
          if(Array.isArray(JSONparseKey)){
            
            JSONparseKey.forEach(key=>{
              if(key!=null)
                JSONparse(data,key)
            })
            
          }
          else
            if(JSONparseKey!=null)
              JSONparse(data,JSONparseKey)
          
          dispatch({
            type: 'JUST_SET_VALUE',
            key: tableName,
            payload:data
          })
        })
        
      }
    }
}




export function getVARS(arg) {
  return (dispatch) => {
      
    GF.API_task({
        operation:'getVARS'
    }).then(data => {
      let obj = {}
      data.forEach(d=>{
        if(!isNaN(d.VALUE))
          d.VALUE=d.VALUE*1
        obj[d.NAME] = {}
        obj[d.NAME].value = d.VALUE
        obj[d.NAME].category = d.CATEGORY
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:obj,
        key:'VARS'
      })
    })
    
  }
}









export function getYCservices(arg) {
  return async (dispatch) => {

    let COMPANYS = [] //ожидание получения данных о компнаиях (филиалах)
    while(COMPANYS.length<=0){
      COMPANYS = store.getState().page.COMPANYS
      await GF.WaitingTimer(10)
    }
      
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/vnd.yclients.v2+json");
    myHeaders.append("Authorization", "Bearer ju3m57rkhandb796rxhm");
    myHeaders.append("Cookie", "__cf_bm=aCIDFyJQsYNnVv9C.I0lszQcuAyfksdzGiv45Q9GUzc-1641379152-0-AUzVx2xyDfv5iME8gXAi0MoWdIXkGv52rOcUGckLliHGt3Etx6wduLJigxmGY0+t7sX91BwmUX8n+xcEB1qATys=; auth=1nvaks4m1rc4c46s2b1icu0jvlm8c9jbjog29riuhdh4leeu7nhbvmsn8l7alh4p");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }


    

    let promises = []
    
    COMPANYS.forEach(company=>{
      console.error(company.ID)
      console.error(store.getState().page.COMPANYS)
      if(company.ID > 1) {
          promises.push(
            new Promise((resolve,reject)=>{
              fetch("https://api.yclients.com/api/v1/book_services/"+company.ID, requestOptions)
              .then(response => response.json()).then(response => resolve({
                ...response,
                company_id:company.ID
              })) .catch(error => reject(error))
            })
            
          )
      }
    })
    
    Promise.all(promises)
      .then(data=>{

        let categoris = []
        let services = []
        

        data.forEach(d=>{
          if(d.success){

            //категории
            d.data.category.forEach(cat=>{
              let find_index = categoris.findIndex(c=>c.id==cat.id)
              if(find_index == -1){
                cat.companys = [d.company_id]
                categoris.push(cat)
              }else{
                categoris[find_index].companys.push(d.company_id)
              }
            })


            //услуги
            d.data.services.forEach(serv=>{
              let find_index = services.findIndex(s=>s.id==serv.id)
              if(find_index == -1){
                serv.companys = [d.company_id]
                services.push(serv)
              }else{
                services[find_index].companys.push(d.company_id)
              }
            })
          }
        })


        dispatch({
          type: 'GET_YC_SERVICES_AND_CATEGORIES',
          payload:{
            services,
            categoris
          }
       })
        
      })
    
    

    
  }


  
}


export function getWidgets(arg){
  return (dispatch) => {
    let tableName = 'WIDGETS'
    GF.API_task({
      operation:'getDataFromTable',
      Table:tableName
    }).then(async data=>{
      
      JSONparse(data,'SERVICES')

      let YC_services = [] //ожидание получения данных
      while(YC_services.length<=0){
        YC_services = store.getState().page.YC.services
        await GF.WaitingTimer(10)
      }
     
      let COMPANYS = [] //ожидание получения данных о компнаиях (филиалах)
      while(COMPANYS.length<=0){
        COMPANYS = store.getState().page.COMPANYS
        await GF.WaitingTimer(10)
      }

      data.forEach((d,i)=>{
        d.SERVICES.forEach((serv,y)=>{
          let service = Object.assign({},YC_services.find(s=>s.id == serv))
          if(service!=null && service.id!=null){
            
            data[i].SERVICES[y] = Object.assign({},service)
            data[i].SERVICES[y].companys.forEach((c,ci)=>{
              data[i].SERVICES[y][ci]= COMPANYS.find(com => com.ID==c)
            })
            
            
          }
        })
      })
      
      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload:data
      })
    })
    
  }
}


export function getSHOP_ITEMS(){
  return (dispatch) => {

    GF.API_task({
      operation:'getDataFromTable',
      Table:'SHOP_ITEMS'
    }).then(async data => {

      let YC_services = [] //ожидание получения данных
      while(YC_services.length<=0){
        YC_services = store.getState().page.YC.services
        await GF.WaitingTimer(10)
      }

      let SHOP_CATEGORIS = [] //ожидание получения данных
      while(SHOP_CATEGORIS.length<=0){
        SHOP_CATEGORIS = store.getState().page.SHOP_CATEGORIS
        await GF.WaitingTimer(10)
      }
      
      let COMPANYS = [] //ожидание получения данных о компнаиях (филиалах)
      while(COMPANYS.length<=0){
        COMPANYS = store.getState().page.COMPANYS
        await GF.WaitingTimer(10)
      }

      


      data.forEach((d,i)=>{
        let cat = SHOP_CATEGORIS.find(c=>c.ID == d.ID_SHOP_CATEGORY)
        if(cat==null)
          data[i].SHOP_CATEGORY_TITLE = null
        else
          data[i].SHOP_CATEGORY_TITLE = cat.TITLE
        
        if(d.ID_SHOP_CATEGORY==2){//если это услгу
          
          data[i].YC=YC_services.find(ycs=>ycs.id==d.YC_ID)
          if(data[i].YC!=null){
            data[i].TITLE= data[i].YC.title
            data[i].COAST = data[i].YC.price_max
            data[i].COMPANYS = data[i].YC.companys
            data[i].COMPANYS = data[i].COMPANYS.map(com=>
              COMPANYS.find(c=>c.ID == com)
            )
          }else{
            data[i].COMPANYS=[]
          }
        }else{ //если это товар
          if(d.COMPANYS==null)
            data[i].COMPANYS = []
          else{
            try{
              data[i].COMPANYS = JSON.parse(data[i].COMPANYS)
              data[i].COMPANYS = data[i].COMPANYS.map(com=>
                COMPANYS.find(c=>c.ID == com)
              )
            }catch(e){
              data[i].COMPANYS = []
            }
          }
        }


        
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'SHOP_ITEMS'
      })
    })


   


  }
}



export function getBONUS_LOG(arg,callBack=()=>{}){
  return async (dispatch) => {

   

    let DATE_START = moment(arg.DATE_START.format('YYYY-MM-DD'),'YYYY-MM-DD').subtract(1,'days').format('YYYY-MM-DD')
   
    let DATE_END = moment(arg.DATE_END.format('YYYY-MM-DD'),'YYYY-MM-DD').add(1,'days').format('YYYY-MM-DD')
   

    const { allRows, totalPagesCount } = await GF.API_task({
      operation:'getBONUS_LOG_size',
      DATE_START,DATE_END
    })
    

    let currentPage = 1
    let HaveError = false



    let CLIENTS = [] //ожидание получения данных
    while(CLIENTS.length<=0){
      CLIENTS = store.getState().page.CLIENTS
      await GF.WaitingTimer(10)
    }

    let BONUS_LOG_TYPES = [] //ожидание получения данных
    while(BONUS_LOG_TYPES.length<=0){
      BONUS_LOG_TYPES = store.getState().page.BONUS_LOG_TYPES
      await GF.WaitingTimer(10)
    }

    let SHOP_ITEMS = [] //ожидание получения данных
    while(SHOP_ITEMS.length<=0){
      SHOP_ITEMS = store.getState().page.SHOP_ITEMS
      await GF.WaitingTimer(10)
    }

    let BONUS_LOG = []

    

    while(currentPage <= totalPagesCount && HaveError===false){
      await new Promise((resolve,reject)=>{
        
        //console.error(document.cookie)
        function getCookie(name) {
          let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined;
        }
        //console.error(getCookie("id"))
        
        GF.API_task({
          operation:'getBONUS_LOG',
          PAGE:currentPage,
          DATE_START,DATE_END,
          ID_USER:getCookie("id")
        }).then(data=>{
         
          data.forEach((d,i)=>{
            data[i].DATE_TIME_CREATE = new Date(data[i].DATE_CREATE.replace(/ /g,"T"))
            data[i].DATE_CREATE = new Date(data[i].DATE_CREATE.substr(0,10)+'T00:00:00')
            
            data[i].CLIENT = Object.assign({},CLIENTS.find(s=>s.ID == d.ID_CLIENT))
            delete(data[i].ID_CLIENT)
            if(d.TYPE=='U' || d.TYPE=='D'){
              data[i].SERVICE = Object.assign({},SHOP_ITEMS.find(s=>s.ID == d.ID_SERVICE))
            }
          })
  
          BONUS_LOG = BONUS_LOG.concat(data)
  
          currentPage++
          setTimeout(()=>{
            resolve()
          },10)
          
        }).catch(er=>{
          HaveError=true
          console.error('getBONUS_LOG ERROR - ',er)
          reject()
        })
      })
    }


      
    setTimeout(()=>{
      callBack()
    },1)

    if(HaveError==false)
      dispatch({
        type: 'JUST_SET_VALUE',
        key: 'BONUS_LOG',
        payload:BONUS_LOG
      })


    
    
  }
}
