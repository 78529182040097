import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getSHOP_ITEMS} from '../../../../actions/PageActions';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../../components/Table';
import DataWindow from '../Services/DataWindow';
import POP_servChange from './POP_TovarChange';
import POP_TovarChange from './POP_TovarChange';
import { Pallet } from '../../../../constants/Constants';
import { Button } from '@mui/material';
import { Search } from '../../CUST/ClientBallItems';


const Rows = [
  {
      title:'YC_id',
      key:'YC_ID',
      width:'50px'
  },
  {
      title:'Название',
      key:'TITLE',
  },
  {
      title:'Стоимость услуги',
      key:'COAST',
  },
]

class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          ID_CATEGORY:1,
          pop_filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

    componentDidMount(){
      
    }

    Save(ID){
      let data = {
        operation:'createUpdateSome',
        Table:'SELECTED_SERVICES',
        data:{},
      }
      Object.keys(this.input.dataWindow).forEach((key,index)=>{
        data.data[key] = this.input.dataWindow[key].value
      })
      if(ID!=null)
        data.ID = ID

      
      GF.API_task(data).then(res=>{
       
        this.props.closePopUp()
          this.props.getShopCategores()
      })  
    }
    
    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'SELECTED_SERVICES',
          ID
        }

        
        
        GF.API_task(data).then(res=>{
          
          this.props.getDB_Data_lazy('SELECTED_SERVICES')
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }


    createTovar(selected){
      if(selected.length==0){
        this.props.openPopUp(<Alert text="Ничего не выбранно"/>)
        return
      }

      selected.forEach(serv=>{
        
        GF.API_task({
          operation:'createUpdateSome',
          Table:'SHOP_ITEMS',
          data:{
            YC_ID:serv.id,
            TITLE:serv.title,
            COAST:serv.price_max,
            ID_SHOP_CATEGORY:2,
            COMPANYS:JSON.stringify(serv.companys)
          }
        }).then(data => {
          this.props.getSHOP_ITEMS()
            this.props.closePopUp()
        })
      })
        
    }


    

    getServTitle(data){
      const {YC} = this.props
      if(YC.services.length==0)
        return null

      let serv = YC.services.find(serv=>serv.id==data.YC_ID)
      if(serv==null)
        return <p style={{color:'red', margin:0}}>Объект не найден в YClients</p>
      return [
        serv.title,
        <br/>,
        data.COMPANYS.length==5?
        <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>Все филиалы</small>:
        data.COMPANYS.map(com=>
          <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>{com.TITLE}</small>  
        )
      ]
    }
  

    render (){
      const {state,props} = this
      const {filter,Data} = this.state
      const {SHOP_ITEMS,SELECTED_SERVICES} = this.props
        return (
        <MainView>
          <div>
            <Button size='small' className='me-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = true
              })
            }}>
                Выбрать все
            </Button>
            <Button size='small' className='me-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = false
              })
            }}>
                Снять выделение
            </Button>
            <Button size='small' className='me-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach(async (key,index)=>{
                if(this.checkBoxs[key].checked){
                  this.checkBoxs[key].checked=false
                  this.Delete(key)
                  delete(this.checkBoxs[key])
                }
              })
              
            }}>
                Удалить выбранные
            </Button>
            <Button size='small' className='me-2' onClick={()=>{
              this.props.openPopUp(<DataWindow callBack={selected=>this.createTovar(selected)} notAllowedInFilter={SHOP_ITEMS.map(serv => {
                
                if(serv.ID_SHOP_CATEGORY==state.ID_CATEGORY)
                  return serv
                else
                return {}
                })}/>)
            }}>
                Добавить
            </Button>

            <Search style={{width:300}} placeholder='Поиск' className='form-control float-start d-block mb-2 me-2' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>
          </div>
          
          <br/>

          <div style={{
            display:'flex',
            gap:20
          }}>
            <Button onClick={()=>this.setState({ID_CATEGORY:1})}>Товары</Button>
            <Button onClick={()=>this.setState({ID_CATEGORY:2})}>Услуги</Button>
          </div>

          <Table
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={SHOP_ITEMS.filter(a=> a.ID_SHOP_CATEGORY==state.ID_CATEGORY && ( filter==null || a.TITLE.toUpperCase().indexOf(filter)!=-1) )}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_TovarChange tovarType='услуга' ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.YC_ID}</td>,
                    <td>{this.getServTitle(data)}</td>,
                    <td>{data.YC==null? '-' : data.YC.price_max} руб</td>,
                   
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`
margin-top:-10px;
`

const Btn = styled.div`

`







const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getSHOP_ITEMS:(arg)=>dispatch(getSHOP_ITEMS(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SHOP_ITEMS:store.page.SHOP_ITEMS,
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      VARS:store.page.VARS,
      YC:store.page.YC,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Index)

