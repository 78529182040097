import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getDB_Data } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';

import POP_FixedBonus from '../FixedBonuses/POP_FixedBonus';
import { Pallet, Styles } from '../../../../constants/Constants';
import { Button } from '@mui/material';



class POP_servChange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          isDisabled:false,
          service_title:this.getServTitle(props.data)
        }
        this.input={}
        this.previosValue = null
        
    }
    componentDidMount(){
      this.getSelf()
    }

    getSelf(){
      Object.keys(this.props.data).forEach(key=>{
        try{
          this.input[key].value=this.props.data[key]
        }catch(e){}
      })

      this.setFixedValues()
    }

    getServTitle(data){
      const {YC} = this.props
      if(YC.services.length==0)
        return null

      if(data.YC_ID<0){
        return GF.getServTitleDEF(data)
      }else{
        let serv = YC.services.find(serv=>serv.id==data.YC_ID)
        if(serv==null)
          return <p style={{color:'red', margin:0}}>Объект не найден в YClients</p>
        return [
          serv.title,
          <br/>,
          serv.companys.length==5?
          <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>Все филиалы</small>  :
          serv.companys.map(com_id=>
            <small style={{background:Pallet.Light.White}}  className='me-2 mt-2 p-1 rounded'>{this.props.COMPANYS.find(c=>c.ID==com_id).TITLE}</small>  
          )
        ]
      }
    }

    setFixedValues(){
      let value = this.input.BONUS_TYPE.value
      const {VARS} = this.props

      if(value.indexOf('GR')==0){//если это значение из таблицы VARS
                
        this.setState({isDisabled:true})
        if(this.input.BONUS.value.length>0)
          this.previosValue = this.input.BONUS.value
        
          switch(value){
            case 'GRP': this.input.BONUS.value = VARS.GRP.value;  break;
          }
        
      }else{
        this.setState({isDisabled:false})
        if(this.previosValue!=null)
          this.input.BONUS.value = this.previosValue
      }

      
    }
    

    Delete(){
      GF.API_task({
        operation:'deleteSome',
        Table:'SELECTED_SERVICES',
        ID:this.props.data.ID
      }).then(data => {
        this.props.getDB_Data('getSELECTED_SERVICES','SELECTED_SERVICES')
          this.props.closePopUp()
      })
    }

    Update(){
      if(!GF.checkReqvireds(this.input)) return

      let reqvest = {
        operation:'createUpdateSome',
        Table:'SELECTED_SERVICES',
        ID:this.props.data.ID,
        data:{}
      }

      Object.keys(this.input).forEach(key=>{
        reqvest.data[key]=this.input[key].value
      })

      GF.API_task(reqvest).then(data => {
        this.props.getDB_Data('getSELECTED_SERVICES','SELECTED_SERVICES')
          this.props.closePopUp()
      })
    }

   
  
    render (){
        const {isDisabled,service_title} = this.state
        const {SERVICES_BONUS_TYPES,VARS} = this.props
        return (
          <POP> 
            <h4>Настройка бонуса</h4>
            
            <small>{service_title}</small>
            <br/><br/>

            <Label>Тип награды</Label>
            <select ref={ref=>this.input.BONUS_TYPE=ref} className='form-control mb-2' onChange={(e)=>this.setFixedValues()}>
              {SERVICES_BONUS_TYPES.map(type=>
                <optgroup label={type.BONUS_TYPE}>
                  <option value={type.BONUS_TYPE}>{type.DESCR}</option>
                </optgroup>
              )}
              
            </select>

            
            <Input Ref={this.input}
            title='Размер награды'
            name='BONUS'
            onChange={e=>{
              e.target.value=e.target.value.replace(/\D/, '')
              this.previosValue = e.target.value
            }}
            disabled={isDisabled}
            
            max={999}
            min={0}
            />
            {this.input.BONUS_TYPE!=null && this.input.BONUS_TYPE.value=='FP1'?
            <Label className='mb-4' onClick={()=>this.props.openPopUp(<POP_FixedBonus callBack={()=>{
              this.setFixedValues()
            }} BONUS_TYPE={this.props.data.BONUS_TYPE}/>)}>Изменить глобальное занчение {this.props.data.BONUS_TYPE}</Label>
            :null}

            <div style={Styles.inline}>
              <Button size='small' color='error' onClick={()=>this.Delete()}>Удалить</Button>
              <Button size='small' onClick={()=>this.Update()}>Сохранить</Button>
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:350px;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getDB_Data:(arg,FGD)=>dispatch(getDB_Data(arg,FGD)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      SERVICES_BONUS_TYPES:store.page.SERVICES_BONUS_TYPES,
      YC:store.page.YC,
      VARS:store.page.VARS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_servChange)