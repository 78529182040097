import { Button, Stack, TextField } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDB_Data } from '../../../actions/PageActions';
import { closePopUp } from '../../../actions/PopUpActions';
import { GF } from '../../../GlobalFunctions';
import Question from '../../PopUp/Views/Question';

export default function POP_ChangeBonusLog({ SelfData, callBack = () => { } }) {
    const dispatch = useDispatch()
    const [Self, setSelf] = useState({ ...SelfData })

    const closePop = () => {
        dispatch(closePopUp())
    }
    const Save = () => {
        closePop()

        GF.API_task({
            operation: 'changeBonusLogItem',
            ID_LOG: Self.ID,
            NEW_VALUE: Self.VALUE * 1
        }).then(() => {
            dispatch(getDB_Data('getCLIENTS', 'CLIENTS', null, () => {
                callBack()
            }))
        })

    }

    return <Question text='Изменение значения в логе баллов'
        style={{ width: 300 }}
        buttons={<div className='w-100'>
            <TextField
                color='warning'
                variant='filled'
                autoFocus
                label='Значение баллов'
                type='number'
                className='mx-auto w-100'
                value={Self.VALUE}
                onChange={(e) => {
                    setSelf({
                        ...Self,
                        VALUE: e.target.value
                    })
                }}
            />
            <Stack justifyContent='space-between' direction='row' sx={{ mt: 2 }}>
                <Button onClick={Save}>Сохранить</Button>
                <Button color='warning' onClick={closePop}>Отмена</Button>
            </Stack>
        </div>} />
}