
import { DriveEtaOutlined } from '@mui/icons-material';
import { Avatar, Button, Chip } from '@mui/material';

import Lottie from "lottie-react";
import React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Pallet } from '../../../constants/Constants';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'

import dots from "../../../lottie/dots.json";
import happy from "../../../lottie/happy.json";
import king from "../../../lottie/king.json";



class LvlUpContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          nowCeshCount:  props.cookies.get('my_cash_count')*1 || 0,
          showing:0,
          
        }

      this.lastLVL = props.cookies.get('my_last_lvl')*1 || 0
      this.addedBalls = props.SELF_CUSTOMER.GLOBAL_COINS - (props.cookies.get('my_last_bals')*1 || 0)
        
    }


    componentDidMount() {
      if(this.props?.SELF_CUSTOMER?.ALL_COAST==null){
        setTimeout(this.componentDidMount(),500)
        return
      }
      if(this.state.nowCeshCount<this.props.SELF_CUSTOMER.ALL_COAST){
        
        this.setState({showing:1})
        setTimeout(()=>{
          this.setState({showing:2})
          this.animFunc()
        },10)
      }
    } 
    

    animFunc(){
      if(this.state.nowCeshCount<this.props.SELF_CUSTOMER.ALL_COAST){
        if(this.props.SELF_CUSTOMER.ALL_COAST - 10 <= this.state.nowCeshCount){
          this.setState({nowCeshCount:this.props.SELF_CUSTOMER.ALL_COAST},()=>{
            this.props.cookies.set('my_last_lvl',this.props.SELF_CUSTOMER.LVL,{ path: '/' })
            this.props.cookies.set('my_cash_count',this.props.SELF_CUSTOMER.ALL_COAST,{ path: '/' })
            this.props.cookies.set('my_last_bals',this.props.SELF_CUSTOMER.GLOBAL_COINS,{ path: '/' })
            
          }) 
        }else{
          let k = (this.props.SELF_CUSTOMER.ALL_COAST - this.state.nowCeshCount)/100

          if(k<0.1) k=0.1
       
          this.setState({nowCeshCount:this.state.nowCeshCount+k},()=>{
            setTimeout(()=>{
              this.animFunc()
             },10)
          })
        }   
      } 
    }

    render(){
      const {state,props} = this
      const {nowCeshCount,showing} = state

      const prevLVL = props.BONUS_LVL[props.SELF_CUSTOMER.LVL-1]
      const currentLVL = props.BONUS_LVL[props.SELF_CUSTOMER.LVL]
      const nextLVL = props.BONUS_LVL[props.SELF_CUSTOMER.LVL+1]
      const nextLVL_2 = props.BONUS_LVL[props.SELF_CUSTOMER.LVL+2]


     
      if(showing==0)
        return(<div/>)

        return(<div style={{
          opacity:showing==2?1:0,
          transition:'.5s',
          position:'fixed',
          height:'100vh',
            width:'100vw',
            background:"rgba(0,0,0,0.5)",
            WebkitBackdropFilter:'blur(10px)',
            backdropFilter:'blur(10px)',
            zIndex:10,
            left:0,
            top:0,
        }}>
          <Lottie style={{
            position:'fixed',
            transform:showing==2?'scale(1)':'scale(0.5)',
            transition:'1s cubic-bezier(.73,1.04,.51,1.08)',
            left:'0px',
            zIndex:10,
            top:'0px',
            height:'100vh',
            width:'100vw'
          }} animationData={dots} />
          <View style={{
          
            borderRadius:5,
            padding:20,
            boxShadow:'5px 5px  30px rgba(0,0,0,0.5)'
          }}>
            <h1>Вам начислены баллы!</h1>
            <p style={{
              marginTop: -15
            }}>За посещение салонов сети Rich Nails вам начислено {this.addedBalls} {GF.okonchanie(this.addedBalls,'балл','балла','баллов')}</p>
            <div style={{
              display:'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent:nextLVL==null?'center':'flex-start'
            }}>
              <div>
                <Lottie style={{
                  width:100,
                  zIndex: 100,
                  position: 'relative',
                  height:100,
                  marginLeft:-17,
                  ...(nextLVL==null?{
                    margin:'auto',
                    marginBottom:-11,
                    marginTop:-25
                  }:{})
                }} animationData={king} />
                {nextLVL==null?<p>Вы достигли макимального уровня</p>:null}
              </div>
              {nextLVL==null?null:<>
                {currentLVL.LVL<=this.lastLVL?null:
                  <div style={{
                    position:'absolute',
                    zIndex:5,
                    background:currentLVL.CASH_COUNT<=nowCeshCount+150?'rgba(240, 156, 96, 1)':'gray',
                    transition:'.5s cubic-bezier(.48,1.65,.31,.82)', 
                    transform:currentLVL.CASH_COUNT<=nowCeshCount+150?'scale(1)':'scale(0.5)',
                    width:50,   
                    height:50, 
                    left:(currentLVL.CASH_COUNT / nextLVL.CASH_COUNT) * 100 +'%',
                    borderRadius:100,
                    textAlign:'center',
                    alignItems:'center', 
                    justifyContent:'center',
                    color:'white',
                    fontWeight:'bold',
                    display:'flex'
                  }}>
                    {currentLVL.LVL}
                  </div>
                }
                <div style={{
                  marginLeft: -20,
                  marginRight:-5,
                  height:20,
                  width:'calc(100% - 115px)',
                  background:'rgb(54, 54, 54)',
                }}>
                  <div style={{
                    position:'relative', 
                    height:20, 
                    borderTopRightRadius:2,
                    borderBottomRightRadius:2,
                    width:(nowCeshCount / nextLVL.CASH_COUNT) * 100 +'%',
                    background:'rgba(240, 156, 96, 1)',
                  }}>
                    <p style={{
                      display: 'block',
                      position: 'absolute',
                      fontSize: 13,
                      bottom: -55,
                      width: 100,
                      right: -100,
                    }}>{GF.makeCoast(nowCeshCount)} руб</p>
                  </div>
                </div>
                <div style={{
                  position:'relative',
                  zIndex:5,
                  background:'gray',
                  width:50,
                  height:50,
                  borderRadius:100,
                  textAlign:'center',
                  alignItems:'center',
                  justifyContent:'center',
                  color:'white',
                  fontWeight:'bold',
                  display:'flex'
                }}>
                  {nextLVL.LVL}
                </div>
              </>}
              
            </div>
            <Button color='warning' style={{
              margin: 'auto',
              display: 'block'
            }} onClick={()=>{
              this.props.cookies.set('my_last_lvl',this.props.SELF_CUSTOMER.LVL,{ path: '/' })
              this.props.cookies.set('my_cash_count',this.props.SELF_CUSTOMER.ALL_COAST,{ path: '/' })
              this.props.cookies.set('my_last_bals',this.props.SELF_CUSTOMER.GLOBAL_COINS,{ path: '/' })
              this.setState({showing:1},()=>{
               setTimeout(()=>{
                this.setState({showing:0})
               },600)
              })
            }}>Отлично!</Button>
          </View>
        </div>)
    }

}


const View = styled.div`
position:fixed;
top:50vh;
left:50vw;
height:255px;
z-index:9999;
transform: translate(-50%, -50%);
width:600px;
max-width:calc(100vw - 16px);
background:rgba(25, 25, 25, 0.8);
backdrop-filter:blur(20px);
`



const mapDispatchToProps = (dispatch) => {
    return {
        
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SELF_CUSTOMER:store.page.SELF_CUSTOMER,
      BONUS_LVL:store.page.BONUS_LVL,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(LvlUpContainer))

