
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../../actions/PopUpActions';
import { Pallet } from '../../../../constants/Constants';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            filter: null,
        }
        this.input = []
    }



    componentDidMount() {

    }


    render() {

        const { state, props } = this

        return (
            <MainView>


                <div className='row'>
                    <div className='col-lg-6 col-md-10 order-lg-1 order-sm-2'>
                        <div className='row row-cols-1'>
                            {props.COMPANYS.map(d =>
                                <div className='col mb-3'>
                                    <Card>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            {d.IMAGES_URL != null && d.IMAGES_URL[0] != null ?
                                                <img style={{
                                                    background: 'rgb(240,240,240)',
                                                    objectFit: 'cover',
                                                    height: 100,
                                                    width: 100,
                                                    borderRadius: 5,
                                                    marginRight: 20
                                                }} src={global.URL_host + 'img/' + d.IMAGES_URL[0]} /> : null}
                                            <div>
                                                <h5>{d.TITLE}</h5>
                                                <small>{d.ADDRESS}</small>
                                            </div>
                                        </div>
                                        {d.HOW_TO_WALK != null && d.HOW_TO_WALK != '' || d.PHONE != null && d.PHONE != '' ? <>
                                            <hr />
                                            <p><a href={'tel:' + d.PHONE}>{GF.phoneFormatter(d.PHONE)}</a></p>
                                            <p>{d.HOW_TO_WALK}</p>
                                        </> : null}
                                    </Card>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-12 order-lg-2 order-sm-1'>
                        <h5>Вы можете воспользоваться бонусной программой в любом филиале сети beauty студий Rich Nails</h5>
                        <p>RICH NAILS – это 5 студий в шаговой
                            доступности от метро. Единый баланс для всех
                            филиалов позволит Вам списывать и
                            накапливать баллы вне зависимости от того,
                            какую студию Вы посетили</p>
                    </div>

                </div>

            </MainView>
        )

    }



}

const MainView = styled.div`

`


export const Card = styled.div`
background:${Pallet.Gray};
border-radius:5px;
padding:20px;
text-align:left;
& h5{
margin-bottom:0px;
margin-top:0px;
font-size:16px;
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (arg) => dispatch(openPopUp(arg))
    };
};


const mapStateToProps = (store) => {

    return {
        SELECTED_SERVICES: store.page.SELECTED_SERVICES,
        CLIENTS: store.page.CLIENTS,
        BONUS_LOG: store.page.BONUS_LOG,
        YC: store.page.YC,
        user: store.user.data,
        SHOP_ITEMS: store.page.SHOP_ITEMS,
        SELF_CUSTOMER: store.page.SELF_CUSTOMER,
        COMPANYS: store.page.COMPANYS,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

