import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import SERVICES from './Services/SERVICES';
import FixedBonuses from './FixedBonuses';
import BayServices from './BayServices';
import BonusKategs from './BonusKategs';
import Companys from './Companys';
import { Button } from '@mui/material';
import Users from './Users';
import BonusDepth from './BonusDepth';


const tabs = [
  {
    title:'Филиалы',
    url:'/companys',
    object:<Companys/>
  },
  {
    title:'Администраторы',
    url:'/users',
    object:<Users/>
  },
  {
    title:'Глубина скидки',
    url:'/bonus_depth',
    object:<BonusDepth/>
  },
  /*
  {
    title:'Списание бонусов на услуги',
    url:'/BayServices',
    object:<BayServices/>
  },
  */
  
  {
    title:'Категории начисления',
    url:'/bonus_kateg',
    object:<BonusKategs/>
  },
]

class Settings extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false
        }
        this.input={}

        
    }

   componentDidMount(){
  
   }

    Create() {
      this.setState({errorMSG:null})
      if(!GlobalFunctions.checkReqvireds(this.input)) return

      if(this.input.PASSWORD.value!=this.input.PASSWORD2.value){
        this.setState({errorMSG:'Пароли не совпадают'})
        return
      }
     

      GlobalFunctions.API_task({
          operation:'confimArendUser',
          EMAIL:this.input.EMAIL.value,
          PASSWORD:this.input.PASSWORD.value,
          GUID:this.props.match.params.GUID
      }).then(res=>{
        this.setState({succes:true})
        
      }).catch(er=>this.setState({errorMSG:er}))
    }


    render (){
      
        return (
        <MainView>
          {tabs.map((tab,index)=>
            <Link to={`/Settings${tab.url}`}>
              <Button className='me-2' color={
                this.props.location.pathname==`/Settings${tab.url}` ||
                (this.props.location.pathname==`/Settings` && index==0)
                ?'primary':'warning'}>
                {tab.title}
              </Button>
            </Link>
          )}
          
          <hr/>
          <Switch>
 
            {tabs.map(tab=>
              <Route path={`/Settings${tab.url}`}>
              {tab.object}
            </Route>
            )}

            <Route path={`/Settings`}>
                {tabs[0].object}
            </Route>

          </Switch>

        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SHOP_CATEGORIES:store.page.SHOP_CATEGORIES
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(withRouter(Settings)))

