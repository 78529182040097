import React from 'react'
import styled from 'styled-components'
import { ArrowDownward } from '@mui/icons-material';
import { Pallet } from '../../constants/Constants';



class Table extends React.Component {
    constructor(props) {
        super()
        this.state = {
            errorMSG: null,
        }
        this.input = []
    }





    render() {
        const { rows, renderItem, data, itemOnClick, rowOnClick, className, canCheck, checkBoxs, orderKey, orderDesc } = this.props

        let { orderView } = this.props

        if (orderView == null) {
            orderView = (
                <OrderIcon orderDesc={orderDesc}>
                    <ArrowDownward />
                </OrderIcon>
            )
        }



        return (
            <TableView className={className}>
                <TableObj className="table">
                    <thead>
                        <tr>
                            {!canCheck ? null :
                                <td width='16'></td>
                            }
                            {rows.map((row, index) =>
                                <td
                                    colSpan={row.colSpan != null ? row.colSpan : 1}
                                    width={row.width != null ? row.width : null}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        try {
                                            rowOnClick({
                                                data: row,
                                                index
                                            })
                                        } catch (e) { }
                                    }}>{row.title} {orderKey != row.key ? null : orderView}</td>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) =>
                            <tr onClick={(e) => {
                                e.stopPropagation()
                                try {
                                    itemOnClick({
                                        event: e,
                                        data: item,
                                        index
                                    })
                                } catch (e) { }
                            }} key={'table_' + index}>
                                {!canCheck ? null :
                                    <td>
                                        <input key={'checkBox_' + item.ID} ref={ref => checkBoxs[item.ID] = ref} class="form-check-input ms-1" type="checkbox" onClick={(e) => e.stopPropagation()} />
                                    </td>
                                }
                                {renderItem({
                                    data: item,
                                    index
                                })}
                            </tr>
                        )}

                    </tbody>
                </TableObj>
            </TableView>
        )
    }
}

const OrderIcon = styled.div`

transition:.3s;
${props => props.orderDesc ? `
transform:rotate(180deg)`: `
transform:rotate(0deg)`};
display: inline-block;
`


const Menu = styled.img`
height:16px;
width:20px;

margin-top: -3px;
transition:.3s;
opacity:0;

`

const TableObj = styled.table`
margin:0px;
margin-top:20px;
color:white;
border-radius: 5px;
overflow: hidden;

background-color:${Pallet.Gray};
& tbody tr td{
    transition:.3s;
    font-size: 12px;
    cursor:pointer;
}
&>tbody>tr:nth-of-type(odd){
    background-color:rgb(40,40,40);
}
&>tbody>tr:hover td{
    background-color:rgb(60,60,60) !important;
}
&>tbody>tr{
    border-bottom-color:rgb(60,60,60) !important;
    color:rgb(200,200,200);
}
&>:not(caption)>*>*{
    box-shadow:none;
}

&>:not(:last-child)>:last-child>*{
    border-bottom-color:${Pallet.Primary.Accent};
    line-height: 13px;
font-size: 14px;
vertical-align: middle;
}

& tr:hover ${Menu}{
    opacity:0.3;
    
}
`



const TableView = styled.div`
border-radius:5px;
overflow:hidden;
display:inline;
`





export default Table

