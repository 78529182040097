import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy} from '../../../../actions/PageActions';
import { GlobalFunctions } from '../../../../GlobalFunctions';
import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../../components/Table';
import POP_BonusKategory from './POP_BonusKategory';
import { Button } from '@mui/material';
import { Search } from '../../CUST/ClientBallItems';



const Rows = [
  {
    title:'Ключ',
    key:'TYPE',
  },
  
  {
    title:'Описание',
    key:'DESCR',
  },
  {
    title:'Системное значение',
    key:'IS_SUSTEM',
},
  
  
]

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,

          pop_filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

   

    render (){
      const {filter,Data} = this.state
      const {BONUS_LOG_TYPES} = this.props
        return (
        <MainView>
          <div>
            
            
            <Search style={{width:300}}  placeholder='Поиск' className='form-control float-start d-block mb-2 me-2' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>

            <Button size='small' className='me-2' onClick={()=>{
              this.props.openPopUp(<POP_BonusKategory />)
            }}>
                Добавить
            </Button>
          </div>

         
          <br/>
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={false}
                data={BONUS_LOG_TYPES.sort((a,b)=>a.DESCR<b.DESCR)}
                itemOnClick={({data,index})=>{
                  if(data.IS_SUSTEM!=1)
                    this.props.openPopUp(<POP_BonusKategory ID_OBJECT={data.TYPE}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.TYPE}</td>,
                    <td>{data.DESCR}</td>,
                    <td>{data.IS_SUSTEM?'Да':'Нет'}</td>,
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`
margin-top:-10px;
`



const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      BONUS_LOG_TYPES:store.page.BONUS_LOG_TYPES,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index)

