import React from 'react';
import { connect } from 'react-redux';
import { closePopUp } from '../../actions/PopUpActions';
import styled from 'styled-components';
import {GlobalFunctions as GF} from '../../GlobalFunctions'
import { Pallet } from '../../constants/Constants';



class PopUp extends React.Component {
    constructor(props) {
        super()
        this.state = {
           visible:false
        }
        this.key = GF.randomString()
    }

    componentDidMount() {
       setTimeout(()=>{
           this.setState({visible:true})
           
       },1)
    }

    
    render (){
        const {zIndex} = this.props
        return ([
                <Bg zIndex={zIndex}/>,
                <Pop zIndex={zIndex} key={this.key} visible={this.state.visible}>
                    <Close onClick={()=>this.props.closePopUp()}>х</Close>
                {this.props.children}
                </Pop>
        ])
    }
}


const Pop = styled.div`
    position: fixed;
    left: 50%;
    transition: .3s ease;
    top: 50%;
    opacity: ${props=>props.visible?"1":"0"};
    height: auto;
    background: ${Pallet.Gray};
    width: max-content;
    border:1px solid rgba(255,255,255,0.05);
    max-width:90vw;
    max-height:90vh;
    box-shadow:5px 5px 50px rgba(0,0,0,1);
    border-radius: 5px;
    z-index:${props=>101+props.zIndex};
    
    transform:${props=>props.visible?" translate(-50%,-50%)":" translate(-50%,-45%)"};
    padding:15px;
    > .Content { 
        margin:20px;
     }; 
`
const Bg = styled.div`
position:fixed;
top:0px;
width:100vw;
height:100vh;
left:0px;
z-index:${props=>100+props.zIndex};
background:rgba(0,0,0,0.05);
`

const Close = styled.div`
    position: absolute;
    right: 5px;
    top: 2px;
    font-weight: 100;
    cursor: pointer;
    opacity: 0.3;
    transition: .3s;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 19px;
    border-radius: 5px;
    color:white;
    box-shadow: 5px 2px 10px rgba(0,0,0,0);
    z-index:20;
    &:hover{
        opacity:1;
        color:black;
        background: rgb(250, 250, 250);
        box-shadow: 5px 2px 10px rgba(0,0,0,0.03);
    }
    &:active{
        font-size:9px
    }

`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: () => dispatch(closePopUp()), 
    };
  };

const mapStateToProps = (store) => {
    
    return {
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(PopUp);