import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy} from '../../../../actions/PageActions';
import { GlobalFunctions } from '../../../../GlobalFunctions';
import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../../components/Table';
import POP_FixedBonus from './POP_FixedBonus';
import { Button } from '@mui/material';
import { Search } from '../../CUST/ClientBallItems';


const Rows = [
  {
      title:'Название',
      key:'NAME',
  },
  {
    title:'Описание',
    key:'DESCR',
  },
  {
    title:'Значение',
    key:'VALUE',
  },
  
  
]

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,

          pop_filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

    getData(){
      const {filter} = this.state
      const {SERVICES_BONUS_TYPES} = this.props
      
      if(SERVICES_BONUS_TYPES.length==0) return []
      let data = []
      Object.keys(this.props.VARS).forEach(key=>{
        let obj = this.props.VARS[key]
        
        if(obj.category=='FIXED_BONUS')
          data.push({
            NAME:key,
            VALUE:obj.value,
            DESCR:SERVICES_BONUS_TYPES.find(b => b.BONUS_TYPE==key).DESCR
          })
      })
      return data.filter(a=> filter==null || a.NAME.toUpperCase().indexOf(filter)!=-1 || a.DESCR.toUpperCase().indexOf(filter)!=-1 || a.VALUE.toUpperCase().indexOf(filter)!=-1 )
    }

    

    render (){
      const {filter,Data} = this.state
      const {YC} = this.props
        return (
        <MainView>
          <div>
            
            
            <Search style={{width:300}} placeholder='Поиск' className='form-control float-start d-block me-2' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>

            <Button size='small' className='me-2' onClick={()=>{
              this.props.openPopUp(<POP_FixedBonus />)
            }}>
                Добавить
            </Button>
          </div>
          
          <br/>
          <Table
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={this.getData()}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_FixedBonus BONUS_TYPE={data.NAME}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.NAME}</td>,
                    <td>{data.DESCR}</td>,
                    <td>{data.VALUE}%</td>,
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`
margin-top:-10px;
`




const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SERVICES_BONUS_TYPES:store.page.SERVICES_BONUS_TYPES,
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      YC:store.page.YC,
      VARS:store.page.VARS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index)

