import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import LogBalls from './LogBalls';
import { Button } from '@mui/material';



const tabs = [
  {
    title:'Лог баллов',
    url:'/log_balls',
    object:<LogBalls/>
  }
]

class Settings extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false
        }

        
    }

  
    


    render (){
      
        return (
        <MainView>
          {tabs.map((tab,index)=>
            <Link to={`/reports${tab.url}`}>
              <Button className='me-2' color={
                this.props.location.pathname==`/reports${tab.url}` ||
                (this.props.location.pathname==`/reports` && index==0)
                ?'primary':'warning'}>
                {tab.title}
              </Button>
            </Link>
          )}
          
          <hr/>
          <Switch>

            {tabs.map(tab=>
              <Route path={`/reports${tab.url}`}>
              {tab.object}
            </Route>
            )}

            <Route path={`/reports`}>
                {tabs[0].object}
            </Route>

          </Switch>

        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SHOP_CATEGORIES:store.page.SHOP_CATEGORIES
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(withRouter(Settings)))

