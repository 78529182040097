import React from 'react';
import styled from 'styled-components';
import { Pallet } from '../../../constants/Constants';
import { GlobalFunctions } from '../../../GlobalFunctions';

export default class Input extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        this.key = GlobalFunctions.randomString()
        this.startValue=null
    }

    componentDidMount() {
        this.ready(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        this.ready(nextProps)
    }

    ready(props){
        const {Name,Ref,Value} = props
        if(this.startValue!=Value)
            Ref[Name].value=Value
    }
    
    render (){
        const {title,name,Ref,type,Dark} = this.props
        return ([
            <Label for={this.key} key={this.key+"L"}>{title}</Label>,
            type=='textarea'?
            <TextAreaView id={this.key} key={this.key+"I"}
            Dark={Dark}
            ref={ref=>Ref[name]=ref}
            className="form-control"
            {...this.props}/> :
            <InputView id={this.key} key={this.key+"I"}
            Dark={Dark}
            ref={ref=>Ref[name]=ref}
            className="form-control"
            {...this.props}/>
        ])
    }
}

export const Label = styled.label`
font-size:11px;
color:white;
opacity:0.7;
display:block;
`

const InputView = styled.input`
font-size:13px;
border: 1px  ${Pallet.Gray} solid;
background: ${props=>props.Dark===false?Pallet.Gray: 'rgba(255,255,255,0.05)'};
color:white;
width:100%;
border-radius:5px;
margin-bottom:10px;
&:active, &:focus{
    border: 1px  ${Pallet.Primary.Accent} solid;
    color:white;
background: ${props=>props.Dark===false?Pallet.Gray: 'rgba(255,255,255,0.05)'};
box-shadow:none;
}

&:disabled, &[readonly]{
    background:black;
}


`
const TextAreaView = styled.textarea`
font-size:13px;
border-radius:5px;
color:white;
border: 1px  ${Pallet.Gray} solid;
background:  ${props=>props.Dark===false?Pallet.Gray: 'rgba(255,255,255,0.05)'};
width:100%;
margin-bottom:10px;
height:60px;
&:active, &:focus{
    border: 1px  ${Pallet.Primary.Accent} solid;
    color:white;
    background:${props=>props.Dark===false?Pallet.Gray: 'rgba(255,255,255,0.05)'};
    box-shadow:none;
}
resize:none;
`
