import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy} from '../../../../actions/PageActions';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import * as AlertView from '../../../PopUp/Views/Alert';
import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../../components/Table';
import DataWindow from './DataWindow';
import POP_servChange from './POP_servChange';
import { Alert, AlertTitle, Button, Chip } from '@mui/material';
import { Search } from '../../CUST/ClientBallItems';
import { Pallet } from '../../../../constants/Constants';


const Rows = [
  {
      title:'YC_id',
      key:'YC_ID',
      width:'50px'
  },
  {
      title:'Название',
      key:'TITLE',
  },

  {
      title:'Тип бонуса',
      key:'BONUS_TYPE',
  },
  {
    title:'Награда',
    key:'BONUS',
  },
]

class SERVICES extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,

          pop_filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

    ChooseServices(selected){
      if(selected.length==0){
        this.props.openPopUp(<AlertView text="Ничего не выбранно"/>)
        return
      }

        GF.API_task({
            operation:'ChooseServices',
            services:selected.map(s=>s.id)
        }).then(data => {
            this.props.getDB_Data_lazy('SELECTED_SERVICES')
            this.props.closePopUp()
        })
    }

    Save(ID){
      let data = {
        operation:'createUpdateSome',
        Table:'SELECTED_SERVICES',
        data:{},
      }
      Object.keys(this.input.dataWindow).forEach((key,index)=>{
        data.data[key] = this.input.dataWindow[key].value
      })
      if(ID!=null)
        data.ID = ID

      
      GF.API_task(data).then(res=>{
       
        this.props.closePopUp()
          this.props.getShopCategores()
      })  
    }
    
    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'SELECTED_SERVICES',
          ID
        }

        
        
        GF.API_task(data).then(res=>{
          
          this.props.getDB_Data_lazy('SELECTED_SERVICES')
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    bonysType(type,value){
      switch(type){
        case 'FIX': return `${value}Б`
        case 'IND': return `${value}%`


        default: return `${this.props.VARS[type]!=null?this.props.VARS[type].value:value}%`
      }
    }

    getServTitle(data){
      const {filter,Data} = this.state
      const {YC} = this.props
      if(YC.services.length==0 )
        return null

      let serv = YC.services.find(serv=>serv.id==data.YC_ID)
      if(serv==null)
        return <p style={{color:'red', margin:0}}>Объект не найден в YClients</p>

      else
        return [
          serv.title,
          <br/>,
          this.props.COMPANYS.length==0?null:
          serv.companys.length==5?
          <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>Все филиалы</small>:
          serv.companys.map(com_id=>
            <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>{this.props.COMPANYS.find(c=>c.ID==com_id).TITLE}</small>  
          )
        ]
    }

    
  

    render (){
      const {filter,Data,selectedBonusType} = this.state
      const {YC,SELECTED_SERVICES,SERVICES_BONUS_TYPES} = this.props
        return (
        <MainView>
          <div>
            <Button size='small' className='me-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = true
              })
            }}>
                Выбрать все
            </Button>
            <Button size='small' className='me-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = false
              })
            }}>
                Снять выделение
            </Button>
            <Button size='small' className='me-2' onClick={()=>{
              Object.keys(this.checkBoxs).forEach(async (key,index)=>{
                if(this.checkBoxs[key].checked){
                  this.checkBoxs[key].checked=false
                  this.Delete(key)
                  delete(this.checkBoxs[key])
                }
              })
              
            }}>
                Удалить выбранные
            </Button>
            <Button size='small' className='me-2' onClick={()=>{
              this.props.openPopUp(<DataWindow callBack={selected=>this.ChooseServices(selected)} notAllowedInFilter={SELECTED_SERVICES}/>)
            }}>
                Добавить
            </Button>

            <Search style={{
              width:300
            }} placeholder='Поиск' className='form-control float-start d-block mb-2' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>

            <select className='form-control float-start d-block mb-2 ms-2 me-2' style={{width:200}} 
            onChange={(e)=>{
              this.setState({selectedBonusType:e.target.value==-1?null:e.target.value})
            }}>
              <option value={-1}>Все услуги</option>
              {SERVICES_BONUS_TYPES.map(s=>
                <option value={s.BONUS_TYPE}>{s.BONUS_TYPE} - {s.DESCR}</option>
              )}
            </select>
          </div>
          
          <br/>
          <br/>
          <h5 className='d-block'>Значения поумолчанию</h5>
          {SELECTED_SERVICES.filter(a=>a.YC_ID<0).map(data=>
          <Alert severity="info" icon={false}  action={
            <Button color="inherit" size="small" onClick={()=>this.props.openPopUp(<POP_servChange data={data}/>)}>
              Изменить
            </Button>
          }>
            <AlertTitle>{GF.getServTitleDEF(data)}</AlertTitle>
            <Chip color='primary' label={<>
               <strong>{data.BONUS_TYPE}</strong> {data.BONUS_DESCR}
               <Chip size='small' style={{
                marginRight:-6,
                backgroundColor:'rgba(255,255,255,0.3)'
               }} className='ms-2' color="primary" label={this.bonysType(data.BONUS_TYPE,data.BONUS)}/>
            </>} />
            
           
            
          </Alert>
          )}
          <br/>
          <h5 className='d-block mb-0'>Правила для услуг</h5>
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={SELECTED_SERVICES.filter(a=>(
                  a.YC_ID>0 && (
                  filter==null ||
                  YC.services.find(serv=>serv.id==a.YC_ID).title.toUpperCase().indexOf(filter)!=-1) && (
                    selectedBonusType ==null || selectedBonusType == a.BONUS_TYPE
                  ))
                )}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_servChange data={data}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.YC_ID}</td>,
                    <td>{this.getServTitle(data)} <small className='text-danger'>{data.category_id!=7773718?null:'Акция'}</small></td>,
                
                    <td><strong>{data.BONUS_TYPE}</strong> {data.BONUS_DESCR}</td>,
                    <td>{this.bonysType(data.BONUS_TYPE,data.BONUS)}</td>,
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`








const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      SERVICES_BONUS_TYPES:store.page.SERVICES_BONUS_TYPES,
      YC:store.page.YC,
      VARS:store.page.VARS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(SERVICES)

