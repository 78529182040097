
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import Table from '../../components/Table';
import POP_Client from './POP_Client';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Button, Chip } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Label } from '../../components/moduls/Input'
import { Search } from '../CUST/ClientBallItems';
import Input from '../../components/moduls/Input'
import Alert from '../../PopUp/Views/Alert';
import { getDB_Data, getUsers } from '../../../actions/PageActions';
import POP_BonusDepth from '../Settings/BonusDepth/POP_BonusDepth';

const Rows = [
    {
        title:'Уровень',
        key:'LVL',
    },
    {
      title:'ФИО',
      key:'FIO',
    },
    {
      title:'Телефон',
      key:'PHONE',
    },    
    {
        title:'E-mail',
        key:'EMAIL',
    }, 
    {
        title:'Счет',
        key:'BONUS_COINS',
    }, 

    {
        title:'Потрачено средств',
        key:'ALL_COAST',
    }, 
    {
        title:'До следующего уровня',
        key:'TO_NEXT_LVL',
    }, 
]


class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            filter:null,
            page:1,
            orderKey:'FIO',
            orderDesc:true,
            filters:{
                ballsGeted:[0,1000],
              }
        }
        this.input=[]
    }

    


    render (){
        const {CLIENTS} = this.props
        console.error('CLIENTS',CLIENTS)
        const {filter,page,orderKey,orderDesc,filters} = this.state

        let CLIENTS_filered = CLIENTS.filter(a=> 
            a.GLOBAL_COINS>=filters.ballsGeted[0] && (a.GLOBAL_COINS<=filters.ballsGeted[1] || filters.ballsGeted[1]==1000) 
            &&
            (filter==null || (
            a.FIO.toUpperCase().indexOf(filter)!=-1 ||
            (a.PHONE!=null && a.PHONE.indexOf(filter)!=-1) ||
            (a.EMAIL!=null && a.EMAIL.toUpperCase().indexOf(filter)!=-1 ))))
        
        let allPageCount  = Math.floor(CLIENTS_filered.length / 20)
        if(allPageCount==0)
            allPageCount = 1
        let paginationView = []

        if(page > 8){
            paginationView.push(
                <p onClick={()=>this.setState({page:1})} key={'page_1'} className='small'>1...</p>
            )
        }

        for(let i=1; i<=allPageCount;i++){
            if(
                page >= 4 && Math.abs(page - i) <= 3 ||
                page<=4 && i <= 7 ||
                page >= allPageCount - 4 && i >= allPageCount - 7
            )
            paginationView.push(
                <p key={'page_'+i} className={page==i?'selected':''} onClick={()=>this.setState({page:i})}>{i}</p>
            )
        }

        if(page < allPageCount - 8){
            paginationView.push(
                <p onClick={()=>this.setState({page:allPageCount})} key={'page_'+allPageCount} className='small'>...{allPageCount}</p>
            )
        }

        
        let CLIENTS_filered_ALL = CLIENTS_filered.slice(0)
        CLIENTS_filered=CLIENTS_filered.sort((a,b)=>orderDesc?
            a[orderKey]>b[orderKey]?1:a[orderKey]<b[orderKey]?-1:0
            :
            b[orderKey]>a[orderKey]?1:b[orderKey]<a[orderKey]?-1:0
        )
        
        CLIENTS_filered =CLIENTS_filered.slice((page - 1) * 20, page * 20)

        return (
           <MainView>
            <FiltersBar>
                <Search style={{
                    width:300
                }} placeholder='Поиск' className='form-control float-start d-block me-4' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>

                <Filter width={250}>
                    {filters.ballsGeted[0]==filters.ballsGeted[1]?
                        <Label>Получено баллов ({filters.ballsGeted[1]==1000?'1000+':filters.ballsGeted[1]})</Label>
                    :
                        <Label>Получено баллов ({filters.ballsGeted[0]} - {filters.ballsGeted[1]==1000?'1000+':filters.ballsGeted[1]})</Label>
                    }
                    <Slider
                    max={1000}
                    color='warning'
                    valueLabelDisplay="bottom"
                    value={filters.ballsGeted}
                    onChange={(e,value)=>{

                        this.setState({filters:{
                        ...filters,
                        ballsGeted:value
                        }})
                    }}
                    />
                </Filter>
            </FiltersBar>

       
            <div style={{
                display:'flex',
                marginTop:10,
                marginBottom:-10,
                alignItems:'center'
            }}>
                <PaginationCont>
                    {paginationView}
                </PaginationCont>

                <Button className='me-2' >
                Всего записей <strong className='ms-2'>{GF.makeCoast(CLIENTS_filered_ALL.length)}</strong>
                </Button>

                {this.props.user.ROLE!='ADMN'?null:
                    <Button className='me-2' onClick={()=> this.props.openPopUp(<div>
                        <p>Получение клиента из YClients</p>
                        <Input title='Номер телефона клиента' 
                        name='PHONE'
                        Ref={this.input}/>

                        <Button size='small' onClick={()=>{
                        GF.API_task({
                            operation:'findeYClient',
                            PHONE:this.input.PHONE.value,
                        }).then((res)=>{
                            if(res.success==false){
                                this.props.openPopUp(<Alert text={res.meta.message}/>)
                            }else{
                                
                                GF.API_task({
                                    operation:'createClient',
                                    PHONE:this.input.PHONE.value,
                                    EMAIL:res.data.clients[0].email,
                                    FIO:res.data.clients[0].name,
                                    YC_ID:res.data.clients[0].id
                                }).then(res=>{
                                    this.props.closePopUp()
                                    this.props.openPopUp(<Alert text={'Клиент добавлен'}/>)
                                    this.props.getUsers()
                                    this.props.getDB_Data('getCLIENTS','CLIENTS')

                                    GF.API_task({
                                        operation:'resendPassword',
                                        ID_USER:res.ID_USER
                                      })

                                    setTimeout(()=>
                                    this.props.openPopUp(<POP_Client ID_OBJECT={res.ID}/>),500)
                                    
                                })
                            }
                            //this.props.closePopUp()
                        }).catch((error)=>{
                            this.props.openPopUp(<Alert text={error}/>)
                        })}}>Найти клиента</Button>
                    </div>)}>Добавить клиента</Button>
                }
            </div>
            <Table
                orderKey={orderKey}
                orderDesc={orderDesc}
                rows={Rows}
                canCheck={false}
                data={CLIENTS_filered}
                itemOnClick={({data,index})=>{
                    
                    this.props.openPopUp(<POP_Client ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    if(orderKey==data.key)
                        this.setState({orderDesc:!orderDesc})
                    else
                        this.setState({orderKey:data.key,orderDesc:false})
                }}
                renderItem={({data,index})=>
                Rows.map(row=>{
                    switch(row.key){
                        case 'LVL': return <td><Chip color={this.props.BONUS_LVL.at(-1)?.LVL == data.LVL?'warning':data.LVL==0?'info':'default'} label={data.LVL} onClick={e=>{
                            e.stopPropagation()
                            this.props.openPopUp(<POP_BonusDepth ID_OBJECT={data.LVL}/>)
                        }}/></td>
                        case 'PHONE': return <td>{GF.phoneFormatter(data.PHONE)}</td>
                        case 'ALL_COAST': return <td>{GF.makeCoast(data.ALL_COAST || 0)}<small> руб</small></td>
                        case 'BONUS_COINS': return <td>{GF.makeCoast(data.BONUS_COINS)} <small> Б</small></td>
                        case 'GLOBAL_COINS': return <td>{GF.makeCoast(data.GLOBAL_COINS)}</td>
                        case 'TO_NEXT_LVL': return <td>{GF.makeCoast((this.props.BONUS_LVL.at(data.LVL+1)?.CASH_COUNT || data.ALL_COAST) - data.ALL_COAST)}<small> руб</small></td>
                        default:return <td>{data[row.key]}</td>
                    }
                })
                }/>

                
           </MainView>
        )
    }
}

const MainView = styled.div`

`
export const PaginationCont = styled.div`
display:flex;
margin-bottom:-10px;

& p{
    margin:0;
    margin-right:20px;
    cursor:pointer;
    text-align:center;
    min-width:40px;
    transition:.3s;
    opacity:0.5;
}
& p:hover{
    opacity:1;
}
& p.selected{
    opacity:1;
    transform:scale(1.5);
}
& p.small{
    opacity:0.3;
}
`
const FiltersBar = styled.div`
display:flex;
alignItems:center;
`
const Filter = styled.div`
margin-right:20px;
width:${props=>props.width}px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getDB_Data:(arg,sd)=>dispatch(getDB_Data(arg,sd)),
        getUsers:(arg)=>dispatch(getUsers(arg)),
    }
}


const mapStateToProps = (store) => {
   
    return {
        user:store.user.data,
        CLIENTS:store.page.CLIENTS,
        BONUS_LVL:store.page.BONUS_LVL
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index);

