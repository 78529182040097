import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy,getVARS } from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import { Button } from '@mui/material';
import { Styles } from '../../../../constants/Constants';



class POP_SearchClients extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          selected:props.selected,
          showAll:false,
          manualSummBall:0
        }
        this.input={
          dataWindow:{}
        }
        
    }

    componentDidMount(){
     
    }
   

    filteredServices(){
      let result = []
      const {pop_filter,selected,showAll} = this.state
      const {CLIENTS} = this.props

      let filteredServices = []

      filteredServices = CLIENTS.slice(0)

      
      

      if(pop_filter!=null){
        
        if(pop_filter.title!=null){
          filteredServices = filteredServices.filter(item =>
            item.FIO.toUpperCase().indexOf(pop_filter.title)!=-1 ||
            item.PHONE.toUpperCase().indexOf(pop_filter.title)!=-1 ||
            item.EMAIL.toUpperCase().indexOf(pop_filter.title)!=-1 
          )
        }
  
      }
      
      let isMoreThen40 = false
      
      if(filteredServices.length>=10 && !showAll){
        filteredServices= filteredServices.splice(0,10)
          isMoreThen40=true
      }
      

      let onClick=(serv)=>{
          
          let selected_tmp = this.state.selected.splice(0)
          let index = selected_tmp.findIndex(s=>s.ID==serv.ID)
          if(index ==-1)//если не выбрана услуга
            selected_tmp.push(serv)
          else
            selected_tmp.splice(index,1)
          
          this.setState({selected:selected_tmp})
      }
      
      
      
      return {
        views:[...filteredServices.map(item=>
          <Services onClick={()=>onClick(item)} selected={selected.findIndex(s=>s.ID==item.ID)!=-1}>
          
          <p style={{fontSize:13}} className='mb-0 mt-4 '>{item.FIO}</p>
          
          <Label>{GF.phoneFormatter(item.PHONE)} {item.EMAIL==''?'':'/'} {item.EMAIL}</Label>
          
          </Services>
        )
        ,isMoreThen40?<p className='mt-5 mb-5' onClick={()=>{
            this.setState({showAll:true})
        }}>Показать все</p>:null
      ],
      data:CLIENTS}
        
    }

    render (){
        const {self,pop_filter,manualSummBall,selected} = this.state
        const {SHOP_CATEGORIS, COMPANYS,currentCount,manual} = this.props
        

        const {views,data} = this.filteredServices()
        

        return (
          <POP> 

            <div className='row'>
              <div className='col-6'>
              <Input    
                onKeyUp={(e)=>{
                  this.setState({pop_filter:{
                      ...pop_filter,
                      title:e.target.value==''?null:e.target.value.toUpperCase()
                  }})
                }}
                title='ФИО / Телефон / Email'
                placeholder=''
                name='TITLE'
                Ref={this.input}/>
              </div>
            </div>
            
            <hr className='mb-0'/>
              
            <ScrollView>
                {views}
            </ScrollView>

            <div style={Styles.inline}>
            
            <Button size='small' onClick={()=>{
              this.props.select(selected)
              this.props.closePopUp()
            }}>Выбрать</Button>

            
              <Button size='small' color='warning' onClick={()=>this.setState({selected:data})}>Выбрать все</Button>
              <Button size='small' color='warning'  onClick={()=>this.setState({selected:[]})}>Отменить выделение</Button>
            </div>
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:400px;
  `



  const Services = styled.div`
  padding-left:5px;
  border-left: 5px solid white;
  transition:.3s;
  cursor:pointer;
  margin-left:-5px;
  ${props=>props.selected?`
  margin-left:0px;
  border-left: 5px solid #c2965c;
  `:null}
  `
  const ScrollView = styled.div`
  overflow-y:auto;
  height:60vh;
  margin-bottom:20px;
  `

 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      CLIENTS:store.page.CLIENTS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_SearchClients)